import React from "react";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

export const Slider = ({onChange, start = 50}) => {

    return (
        <Nouislider 
            className={'red-slider-custom'}
            range={{ min: 0, max: 100 }} 
            start={start} 
            connect={[true, false]}
            step={1}
            onChange={onChange}
        />
    )
}
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/routes.js";
import { useState, createContext, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";

// import css
import './assets/css/custom.css'
import './assets/css/dash.css'
import { fetchUserThunk } from "./store/thunks/applicationThunks.js";
import AuthModal from "./Modals/AuthModal.jsx";
import { getAuthorized } from "./store/slices/applicationSlice.js";
import {io} from "socket.io-client";
import {setSocket} from "./store/slices/shipsSocket";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

export const ThemeContext = createContext();

function App() {
    const [theme, setTheme] = useState('')

    const changeTheme = (e) => {
        theme ? setTheme('') : setTheme('dark')
    }
    const dispatch = useDispatch();
    const authorized = useSelector(getAuthorized);
    useEffect(() => {
        const token = localStorage.getItem('authToken');

        const req = async () => {
            const res = await dispatch(fetchUserThunk());
            if (res?.payload?.isDarkMode) {
                res.payload.isDarkMode ? setTheme('dark') : setTheme('')
            }

            const socket = io('https://oshen.webstaginghub.com', {
                path: '/socket',
                transports: ['websocket'],
                auth: {
                    token
                }
            });
            socket.on('updated-mission-status', (data) => {
                if(data?.mission?.status === "complete") {
                    const filters = JSON.parse(localStorage.getItem("filters")) || []
                    
                    const newFilter = {
                        vessel: data.mission.vesselId,
                        id: filters.length + 1,
                        name:  data.mission.description,
                        startDate: data.mission.start,
                        endDate: data.mission.end
                    }
                    const newFilters = [...filters, newFilter]
                    console.log(newFilters)
                    localStorage.setItem("filters", JSON.stringify(newFilters))
                }
            })

            dispatch(setSocket(socket))
        }
        req()

    }, [dispatch])
    return (
        <ThemeContext.Provider value={{ theme, changeTheme }}>

            <div className={`App ${theme} min-h-screen`}>
                {/* <button onClick={changeTheme} className="theme-button ">{theme ? 'White mode' : 'Dark mode'}</button> */}
                <RouterProvider router={router} />
                {authorized === false && <AuthModal />}
            </div>
            <ToastContainer />

        </ThemeContext.Provider>
    );
}

export default App;

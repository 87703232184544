import { ReactComponent as BinIcon } from "../../../assets/img/icons/bin.svg"


const PopupContent = ({ data, prevItemData = null, isHistorical, currentMission }) => {
    console.log(currentMission, 'HELLO')
    let distanceInKm = data && data?.distanceToPrevious ? data?.distanceToPrevious : 0;
    const hours = ((distanceInKm / 1000) / 30);
    const latDisplay = data?.lat
    const lngDisplay = data?.lng || data?.lon
    const disabled = isHistorical || currentMission?.status === 'complete'
    return <div className="flex flex-col gap-[4px] ">
        <div className="flex items-center justify-between">
            <div>
                {/* {prevItemData ? <p className="text-[12px] font-semibold text-[#0B0B0B80]">ETA: {hours ? `${Math.floor(hours)}hr` : ''} {hours ? String(((60 * (hours % 1)) / 100)).split('.')[1].slice(0, 2) : ""} mins</p> : null} */}
                {<p className="text-[12px] font-semibold text-[#0B0B0B80]">Distance: {distanceInKm.toFixed(2)} m</p>}
                <input disabled={disabled} className="input lat-input" type="text" value={latDisplay} />
                <input disabled={disabled} className="input lng-input mt-1" type="text" value={lngDisplay} />
            </div>
            {
                !disabled ?
                    <div className="delete-waypoint-btn  cursor-pointer">
                        <div className="pointer-events-none">
                            <BinIcon />
                        </div>
                    </div>
                    :
                    null
            }
        </div>
        <div className="flex items-center gap-[4px]">
            <p className="text-[12px] flex-auto font-semibold text-[#0B0B0B80]">Loop from Waypoint</p>
            <div className="h-[32px] rounded-[4px] border border-[#0B0B0B40] py-[4px] px-[8px] flex items-center gap-[4px] w-[32px]">
                <input type="text" className="bg-inherit block w-full h-[16px] text-[12px] repeat-trajectory-input" disabled={disabled} readOnly={disabled} placeholder="X" value={data?.repeat_trajectory || ""} onInput={() => { }} />
            </div>
            {/* <p className="text-[12px] font-semibold text-[#0B0B0B80]">point</p> */}
        </div>
        <div className="flex items-center gap-[4px] whitespace-nowrap">
            <p className="text-[12px] font-semibold text-[#0B0B0B80]">Radius</p>
            <div className="h-[32px] flex-auto rounded-[4px] border border-[#0B0B0B40] py-[4px] px-[8px] flex items-center gap-[4px]">
                <input className="bg-inherit block w-full h-[16px] text-[12px] target-radius-input" disabled={disabled} readOnly={disabled} placeholder="X" type="number" min={1} max={65535} value={data?.targetRadius || 1} onInput={() => { }} />
            </div>
            <p className="text-[12px] font-semibold text-[#0B0B0B80]">m</p>
        </div>
        {data?.type === 'station_keeping' && <div className="flex gap-[4px] whitespace-nowrap flex-col">
            <p className="text-[12px] font-semibold text-[#0B0B0B80]">Stationkeeping</p>
            <div className="flex items-center gap-[4px] whitespace-nowrap">
                <div className="h-[32px] flex-auto rounded-[4px] border border-[#0B0B0B40] py-[4px] px-[8px] flex items-center gap-[4px]">
                    <input className="bg-inherit block w-full h-[16px] text-[12px] station-days-input" disabled={disabled} readOnly={disabled} placeholder="X" type="number" min={0} max={65535} value={data?.stationDays || 0} onInput={() => { }} />
                </div>
                <p className="text-[12px] font-semibold text-[#0B0B0B80]">d</p>
                <div className="h-[32px] flex-auto rounded-[4px] border border-[#0B0B0B40] py-[4px] px-[8px] flex items-center gap-[4px]">
                    <input className="bg-inherit block w-full h-[16px] text-[12px] station-hours-input" disabled={disabled} readOnly={disabled} placeholder="X" type="number" min={0} max={65535} value={data?.stationHours || ""} onInput={() => { }} />
                </div>
                <p className="text-[12px] font-semibold text-[#0B0B0B80]">h</p>
            </div>
        </div>}
    </div>
}

export default PopupContent
import React, { useContext, useEffect, useState } from 'react';
import mapboxgl from "mapbox-gl";
import { ThemeContext } from '../App';

import "mapbox-gl/dist/mapbox-gl.css";

const DashboardWidgetMap = ({pointClickCallback}) => {

    const { theme, changeTheme } = useContext(ThemeContext)

    const [map, setMap] = useState(null)


    const points2 = [ 
        {
            coordinates: [10.034540530451984, 57.7622512796279],
            // name: 'Point A',
            hidden: true,
            count: true,
            number: 0
        },
        {
            coordinates: [10.034540530451984, 57.7622512796279],
            // name: 'Point A',
            hidden: true,
            count: true,
            number: 0
        },
        {
            coordinates: [10.034540530451984, 57.7622512796279],
            // name: 'Point A',
            hidden: true,
            count: true,
            number: 0
        },
    ]
    
    const points3 = [ 
        {
            coordinates: [26.11789582752943, 59.7909497120938],
            // name: 'Point A',
            hidden: true,
            count: true,
            number: 0
        },
        {
            coordinates: [26.11789582752943, 59.7909497120938],
            // name: 'Point A',
            hidden: true,
            count: true,
            number: 0
        },
        {
            coordinates: [26.11789582752943, 59.7909497120938],
            // name: 'Point A',
            hidden: true,
            count: true,
            number: 0
        },
    ]

    const points4 = [ 
        {
            coordinates: [11.74919191249141, 34.26015754444893],
            // name: 'Point A',
            hidden: true,
            count: true,
            number: 0
        },
        {
            coordinates: [11.74919191249141, 34.26015754444893],
            // name: 'Point A',
            hidden: true,
            count: true,
            number: 0
        },
        {
            coordinates: [11.74919191249141, 34.26015754444893],
            // name: 'Point A',
            hidden: true,
            count: true,
            number: 0
        },
    ]

    const points = [
        
        {
            coordinates: [-1.9338039634599191, 50.631741200851174],
            // name: 'Point A',
            hidden: true,
            count: false,
            number: 0
        },
        {
            coordinates: [-1.903640865511036, 50.63725600132158],
            // name: 'Point A',
            hidden: true,
            count: false,
            number: 0
        },
        {
            coordinates: [-1.857736776420309, 50.63882677591661],
            // name: 'Point A',
            hidden: true,
            count: false,
            number: 0
        },
        


        {
            coordinates: [-1.8443541948242204, 50.63304864438433],
            // name: 'Point A',
            hidden: true,
            count: false,
            number: 0
        },
        {
            coordinates: [-1.843926649348957, 50.62396865807292],
            // name: 'Point A',
            hidden: true,
            count: false,
            number: 0
        },
        {
            coordinates: [-1.8576829786095628, 50.61582960003189],
            // name: 'Point A',
            hidden: true,
            count: false,
            number: 0
        },

        {
            coordinates: [-1.9142276228235588, 50.57915418226773],
            // name: 'Point A',
            hidden: true,
            count: false,
            number: 0
        },
        {
            coordinates: [-1.9033151479035704, 50.56840703167487],
            // name: 'Point A',
            hidden: true,
            count: false,
            number: 0
        },
        {
            coordinates: [-1.8730027175705688, 50.56687172444776],
            type: 'boat',
            count: false,
            hidden: true,
            number: 0
        },
        
    ];


    const targets = [
        {
            coordinates: [-1.8730027175705688, 50.56687172444776],
            bearing: 45,
            name: 'Target'
        },
        
    ];

    const customClick = (e) => {
        if (Object.values(e.target.classList).includes('photo-point')) {
            pointClickCallback && pointClickCallback()
        }
    }

    useEffect(() => {
        document.addEventListener('click', customClick)

        return () => document.removeEventListener('click', customClick)
    }, [])


    useEffect(() => {

        if (!map) return
        console.log('hello')
        const pointsArr = points.map(point => {

            const a = document.createElement('div');
            const attrValue = point.numberOn;
    
            a.classList.add('photo-point');
            if (point.hidden) {
                a.classList.add('photo-point-hidden');  
            }
            a.setAttribute('data-number', attrValue);
    
            const marker = new mapboxgl.Marker({ element: a })
                .setLngLat(point.coordinates)
                .addTo(map);
    
            a.classList.add(`photo-point-${attrValue}`);
    
            return { marker, point };
        })


        const targetsArr = targets.map(point=> {
            const a = document.createElement('div')

            a.classList.add('map-ship-marker')

            const marker = new mapboxgl.Marker({ element: a })
                .setLngLat(point.coordinates)
                .addTo(map);

                return { marker, point };
                
        });

        const lineAfterCoord = []
        const lineBeforeCoord = []

        points.forEach(point => {
            if (point.type === 'boat') {
                lineBeforeCoord.push(point.coordinates)
                lineAfterCoord.push(point.coordinates)
                return
            }
            if (!point.number) {
                lineBeforeCoord.push(point.coordinates)
            } else {
                lineAfterCoord.push(point.coordinates)
            }
        })

        

    // Создаем линию, соединяющую точки
    const newLine = new mapboxgl.NavigationControl({
        showCompass: false
    });
    map.addControl(newLine);

    const lineGeojson = {
        type: 'Feature',
        properties: {},
        geometry: {
            type: 'LineString',
            coordinates: lineAfterCoord
        }
    };

    const lineGeojsonBefore = {
        type: 'Feature',
        properties: {},
        geometry: {
            type: 'LineString',
            coordinates: lineBeforeCoord
        }
    };
    

    map.on('style.load', () => {

        map.addSource('line-source', {
            type: 'geojson',
            data: lineGeojson
        });
    
        map.addSource('line-source-before', {
            type: 'geojson',
            data: lineGeojsonBefore
        });

        map.setFeatureState({
            source: 'line-source-before',
            id: 'line-before' 
        }, {
            dark: theme ? true : false  
        });


        map.addLayer({
            id: 'line',
            type: 'line',
            source: 'line-source',
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
                'visibility': 'none'
            },
            paint: {
                'line-color': '#3477b5',
                'line-width': 2,
                'line-dasharray': [3, 3]
            }
        });

        map.addLayer({
            id: 'line-before',
            type: 'line',
            source: 'line-source-before',
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
                'visibility': 'none'
            },
            paint: {
                'line-color': [
                    'case',
                    ['boolean', ['feature-state', 'dark'], true],
                    '#343c44', 
                    '#000' 
                ],
                'line-width': 2,
                'line-dasharray': [1, 3]
            }
            
        });

    })

    const handleZoom = () => {
        const zoomLevel = map.getZoom();

        pointsArr.forEach(({ marker, point }) => {
            marker.getElement().style.display = 'block';
        });
        targetsArr.forEach(({ marker, point }) => {
            marker.getElement().style.display = 'block';
        });

        map.setLayoutProperty('line', 'visibility', 'visible');
        map.setLayoutProperty('line-before', 'visibility', 'visible');
    }
    setTimeout(() => handleZoom(), 100)
    
    map.on('zoom', handleZoom);

    


    }, [map])

    

    useEffect(() => {

        setMap(null)


        mapboxgl.accessToken = process.env.REACT_APP_MAPBOXKEY;
        
        const currentMap = new mapboxgl.Map({
            container: 'map-container',
            // style: theme ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/streets-v11',
            style: theme ? 'mapbox://styles/maximokolzin/clth2mbte018x01pj2usp8uxu' : 'mapbox://styles/maximokolzin/clth258fl00br01nr4uhb69vt',
            center: [-1.875715476596882, 50.61610028107245],
            zoom: 10,
        });



        const clusterData = [points, points2, points3, points4].map(it => {
            return it.map(el => {
                if (!el.count) return false

                return {
                    "geometry": {
                    "type": "Point",
                    "coordinates": [...el.coordinates, 0]
                }
            }}).filter(el => !!el)
            

        })

        const clast = clusterData.flatMap(el => el)


        currentMap.on('style.load', () => {
            currentMap.addSource('earthquakes', {
                type: 'geojson',
                
                
                data: {features: clast},
                cluster: true,
                clusterMaxZoom: 20,
                clusterRadius: 50,
            });


            // currentMap.addLayer({
            //     id: 'clusters',
            //     type: 'circle',
            //     source: 'earthquakes',
            //     filter: ['has', 'point_count'],
            //     paint: {
            //         'circle-color': [
            //             'step',
            //             ['get', 'point_count'],
            //             'red',
            //             100,
            //             'red',
            //             750,
            //             'red'
            //         ],
            //         'circle-radius': [
            //             'step',
            //             ['get', 'point_count'],
            //             20,
            //             100,
            //             30,
            //             750,
            //             20,
            //         ]
            //     }
            // });

            currentMap.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'earthquakes',
                filter: ['has', 'point_count'],
                paint: {
                    'circle-color': [
                        'step',
                        ['get', 'point_count'],
                        '#e42320',
                        100,
                        '#e42320',
                        750,
                        '#e42320'
                    ],
                    'circle-radius': [
                        'step',
                        ['get', 'point_count'],
                        20,
                        100,
                        30,
                        750,
                        20,
                    ],
                    'circle-stroke-width': 10,
                    'circle-stroke-opacity': 0.5,
                    'circle-stroke-color': '#e42320',
                }
            });
    
    
    
            currentMap.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'earthquakes',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': ['get', 'point_count_abbreviated'],
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 12,
                    
                },
                paint: {
                    'text-color': 'white',
                }
            });

        })

        
        currentMap.on('click', 'clusters', (e) => {
            const features = currentMap.queryRenderedFeatures(e.point, {
                layers: ['clusters']
            });
            const clusterId = features[0].properties.cluster_id;
            currentMap.getSource('earthquakes').getClusterExpansionZoom(
                clusterId,
                (err, zoom) => {
                    if (err) return;

                    currentMap.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom + 1
                    });
                }
            );
        });


        currentMap.on('mouseenter', 'clusters', () => {
            currentMap.getCanvas().style.cursor = 'pointer';
        });
        currentMap.on('mouseleave', 'clusters', () => {
            currentMap.getCanvas().style.cursor = '';
        });


        currentMap.on('click', function(e) {
            var coordinates = e.lngLat;
            console.log('point:', [coordinates.lng, coordinates.lat]);
        });

        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });
    
        // Добавление события для кластеров
        currentMap.on('mouseenter', 'clusters', (e) => {
            currentMap.getCanvas().style.cursor = 'pointer';
    
            const coordinates = e.features[0].geometry.coordinates.slice();
            const count = e.features[0].properties.point_count;
            const description = `Vessel 1 <br/> Vessel 2 <br/> Vessel 3`;
    
            // Установка контента попапа и его координат
            popup.setLngLat(coordinates)
                .setHTML(description)
                .addTo(currentMap);
        });
    
        // Удаление попапа при уходе с кластера
        currentMap.on('mouseleave', 'clusters', () => {
            currentMap.getCanvas().style.cursor = '';
            popup.remove();
        });
        


        setMap(currentMap)

        

        return () => {
            currentMap.remove()
            currentMap.off('mouseenter', 'clusters');
            currentMap.off('mouseleave', 'clusters');
            popup.remove();};
    }, [theme]);
  
    return (
        <div id="map-container" style={{ width: '100%', height: '100%' }}></div>
    );
};
  
export default DashboardWidgetMap;
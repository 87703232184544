import { useState } from "react"

export const TestPage = () => {


    return (
        <div>
            <div className="bg-gray-500 dark:bg-green-500 w-[200px] h-[200px]">

            </div>
            <div className="dark:bg-gray-500 bg-green-500 w-[200px] h-[200px]">

            </div>
        </div>
    )
}
import React, { useState } from 'react'
import InputBox from '../../components/base/InputBox'
import PanelButton from '../../components/base/PanelButton'
import api from '../../api'
import { useSearchParams, useNavigate } from 'react-router-dom';

export default function ResetPassword() {
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    console.log(searchParams.get('token'))
    const [formData, setFormData] = useState({
        "newPassword": "",
    })
    const handleRegister = async () => {
        setErrorMessage(null);
        const response = await api.auth.resetPassword({
            ...formData,
            email: searchParams.get('email'),
            token: searchParams.get('token'),
        });
        setErrorMessage(response.message);
        if(response.success) {
            setTimeout(() => {
                navigate('/')
            }, 1000)
        }
    }
    const [errorMessage, setErrorMessage] = useState(null);
  return <div style={{    
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
        backdropFilter: 'blur(5px)'
    }} >
        <div 
            className="flex flex-col text-xs tracking-wide rounded bg-zinc-50 w-[372px] border border-solid border-neutral-950 border-opacity-30 dark:border-[#ffffff1a] dark:bg-[#1b1b1b]"
        >
            <div className="flex gap-5 justify-between px-2 py-1 w-full bg-gray-100 border-b border-solid border-neutral-950 border-opacity-10 text-neutral-950 text-opacity-50 dark:text-white dark:bg-[#151515]">
                <div>Reset Password</div>
                
            </div>
            <div className="flex flex-col gap-3 px-3 pt-2 pb-3">
                <label>
                    <div className="mb-[8px]">Enter New Password</div>
                    <InputBox 
                        type='password'
                        placeholder='Password'
                        autoComplete='new-password'
                        value={formData.newPassword}
                        onChange={(e) => setFormData((data) => ({...data, newPassword: e.target.value}))}
                    />
                </label>
                <PanelButton
                    onClick={handleRegister}
                >
                    Reset Password
                </PanelButton>
                {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
            </div>
        </div>
    </div>
}

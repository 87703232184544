import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

export const PieChart = (
	{
		title="Chart Title", 
		data=[25, 20.8, 41.7, 12.5],
		labels=['25%', '20.8%', '41.7%', '12.5%'],
		backgroundColor=[
			'rgba(54, 162, 235, 0.2)',
			'rgba(0, 206, 209, 0.2)',
			'rgba(54, 162, 235, 0.4)',
			'rgba(75, 192, 192, 0.2)',
		],
		borderColor=[
			'rgba(54, 162, 235, 1)',
			'rgba(0, 206, 209, 1)',
			'rgba(54, 162, 235, 1)',
			'rgba(75, 192, 192, 1)',
		]
	}) => {
	const chartRef = useRef(null);
	const chartConfig = {
		type: 'pie',
		data: {
			labels,
			datasets: [
				{
					label: 'Dataset 1',
					data: data,
					backgroundColor,
					borderColor,
					borderWidth: 1,
				},
			],
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
				position: 'bottom',
				align: 'center',
				labels: {
					boxWidth: 12,
					boxHeight: 12,
					fillStyle: '#caddf5',
				},
				},
			},
		},
	};
 	 useEffect(() => {
		const myChart = new Chart(chartRef.current, chartConfig);

		return () => {
		myChart.destroy();
		};
	}, []);

	return (
		<div className="widget-inner h-56">
			<div className="text-xs font-semibold tracking-wide text-neutral-950 max-md:max-w-full dark:text-white">
				{title}
			</div>
			<div className="h-44 w-full pt-2">
				<canvas ref={chartRef} />
			</div>
		</div>
	);
};

import { useEffect, useState } from "react";
import PanelButton from "../components/base/PanelButton";
import { ReactComponent as SaveIcon } from "../assets/img/icons/save.svg";
import ReactDatePicker from "react-datepicker";
import { ReactComponent as ClockIcon } from "../assets/img/icons/clock.svg";
import { ReactComponent as CalendarIcon } from "../assets/img/icons/calendar.svg";
import api from '../api';
import { getAllMissions } from "../store/slices/missionSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllMission } from "../store/thunks/fleetThunks";
import { useNavigate } from "react-router-dom";

const InputField = ({ label, placeholder, value, onChange }) => (
    <div className="flex flex-col mt-2 dark:text-white">
        <div>{label}</div>
        <input
            className="px-2 pt-1 pb-7 mt-2 whitespace-nowrap bg-white rounded border border-solid border-neutral-950 border-opacity-30 text-notmal text-neutral-950 dark:bg-[#0B0B0B] dark:text-white"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        />
    </div>
);

const ConfigHeader = ({onClose, type}) => (
    <div className="flex gap-5 justify-between px-2 py-1 w-full bg-gray-100 border-b border-solid border-neutral-950 border-opacity-10 text-neutral-950 text-opacity-50 dark:text-white dark:bg-[#151515]">
        <div>{ type === 'save' ? 'Save Mission' : 'Push Mission' }</div>
        <button onClick={onClose}>
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ddcb33ebb0cb0d777eaebba2ca6701637f967354c4f2b41114a225bd26c5a850?apiKey=9106ba04c02e4dceae488e75e2ac4862&"
                className="shrink-0 my-auto w-3 aspect-square close-icon"
            />
        </button>
    </div>
);

const ConfirmButton = ({ onClick }) => (
    <button
        className="justify-center self-end px-1 py-2 mt-2 text-white whitespace-nowrap bg-red-600 rounded border border-solid border-neutral-950 border-opacity-30"
        onClick={onClick}
    >
        Confirm
    </button>
);

const MissionModal = ({ dashItem, onConfirm, close, type, missionId, }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const allMissions = useSelector(getAllMissions);
    const currentMission = allMissions?.find(item => +item?.id === +missionId);

    console.log('currentMission:', currentMission);

    const [name, setName] = useState(dashItem?.name || "");
    const [description, setDescription] = useState(dashItem?.description || "");

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };


    const [startDate, setStartDate] = useState(null);
    const onChange= (date) => setStartDate(date);


    const handleConfirm = async () => {
        // Handle form submission here
        // console.log("Name:", name);
        // console.log("Description:", description);
        // onConfirm({ ...dashItem, name, description })
        onConfirm && onConfirm()

        if(!description){
            return false;
        }
        if(type === 'push') {
            const pushRes = await api.fleet.pushMission(missionId);
            console.log(pushRes)
            if(!pushRes.success) return false
            
        }

        let res = await api.fleet.updateMission(type === 'push' ? {
            start: startDate,
            status: "in_progress",
            description
        } : {
            status: "draft",
            description
        }, missionId);

        close();
        navigate('/mission-planner');

        setTimeout(() => {
            dispatch(fetchAllMission());
        }, 100);

        // console.log('updateMission res:', res);
    };

    useEffect(()=>{
        dispatch(fetchAllMission())
    }, []);

    useEffect(()=>{
        if(currentMission){
            setDescription(currentMission?.description);
        }

        if(type === 'push' && currentMission?.start){
            setStartDate(new Date(currentMission?.start));
        }
    }, [currentMission]);

  return (
    <div style={{    
            position: 'fixed',
            width: type === 'save' ? 262 : 372,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }} 
        className="flex flex-col text-xs tracking-wide rounded bg-zinc-50 max-w-[336px] border border-solid border-neutral-950 border-opacity-30 dark:border-[#ffffff1a] dark:bg-[#1b1b1b]">
        <ConfigHeader type={type} onClose={close}/>
        <div className="flex flex-col justify-end px-3 pb-3 w-full font-semibold text-neutral-950">
            
            <InputField
                label="Mission Description"
                placeholder="Description"
                value={description}
                onChange={handleDescriptionChange}
            />
            {type === 'push' && <div className="mt-[12px] mb-[8px]">Schedule Start Date and Time</div>}
            {type === 'push' && <div className='flex gap-[12px]'>
                <ReactDatePicker
                    selected={startDate}
                    onChange={onChange}
                    wrapperClassName="flex-1"
                    className="mb-[4px] z-[999] text-[12px] text-[#0B0B0B80] rounded-[4px] border border-solid border-[#0B0B0B40] h-[32px] !w-full block !p-[8px]"
                    dateFormat={'dd/MM/yy'}
                    showIcon
                    calendarIconClassname="right-0 cursor-pointer"
                    icon={<CalendarIcon className='icon-black-50' />}
                    toggleCalendarOnIconClick
                    placeholderText="Date"
                    popperClassName="!z-[999]"
                />
                <ReactDatePicker
                    selected={startDate}
                    onChange={onChange}
                    wrapperClassName="flex-1"
                    className="mb-[8px] z-[999] text-[12px] text-[#0B0B0B80] rounded-[4px] border border-solid border-[#0B0B0B40] h-[32px] !w-full flex-1 block !p-[8px]"
                    dateFormat="h:mm aa"
                    showIcon
                    calendarIconClassname="right-0 cursor-pointer"
                    icon={<ClockIcon className='icon-black-50'/>}
                    toggleCalendarOnIconClick
                    placeholderText="Time"
                    popperClassName="!z-[999]"
                    showTimeSelectOnly
                    showTimeSelect
                />
               
            </div>}
            <PanelButton
                Icon={SaveIcon}
                className='w-fit ml-auto mt-[12px]'
                onClick={(e)=>{
                    e.preventDefault();
                    handleConfirm();
                }}
            >
                {type === 'save' ? 'Save' : 'Push'}
            </PanelButton>
        </div>
    </div>
  );
};

export default MissionModal;
import { configureStore } from '@reduxjs/toolkit';
import applicationSlice from './slices/applicationSlice';
import missionSlice from "./slices/missionSlice";
import shipsSocket from "./slices/shipsSocket";

const store = configureStore({
    reducer: {
        application: applicationSlice,
        mission: missionSlice,
        shipsSocket: shipsSocket
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: ['shipsSocket/setSocket'],
                // Ignore these field paths in all actions
                ignoredActionPaths: [''],
                // Ignore these paths in the state
                ignoredPaths: [
                    'shipsSocket.socket'
                ],
            },
        }),
});

export default store;
import ResetPassword from "../../pages/ResetPassword";

const authBase = 'auth';

export const auth = (instance) => ({
    async login(payload) {
        try {
            const { data } = await instance.post(`${authBase}/login`, payload);
            return data;
        } catch (e) {
            return e.response.data
        }
    },

    async register(payload) {
        try {
            const { data } = await instance.post(`${authBase}/registration`, payload);
            return data;
        } catch (e) {
            return {
                success: false,
                message: e.response.data.error
            }
        }
    },

    async logout() {
        const { data } = await instance.get(`${authBase}/logout`);
        return data;
    },

    async passwordRecoveryRequest(payload) {
        try {
            const { data } = await instance.post(`${authBase}/password-recovery`, payload);
            return data;
        } catch (e) {
            return {
                success: false,
                message: e.response.data.error
            }
        }
    },

    async resetPassword(payload) {
        try {
            const { data } = await instance.post(`${authBase}/password-reset`, payload);
            return data;
        } catch (e) {
            return {
                success: false,
                message: e.response.data.error
            }
        }
    },
});
import React, {useContext, useEffect, useState} from 'react';
import classNames from 'classnames'
import LoadImage from '../../assets/img/load-image.png';
import { ThemeContext } from '../../App';
import ChangePasswordModal from '../../Modals/ChangePasswordModal';
import PanelButton from '../../components/base/PanelButton';
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "../../store/slices/applicationSlice";
import {
    fetchUpdateProfileNotificationsThunk,
    fetchUpdateProfileThunk,
    fetchUploadImageThunk,
    logoutThunk
} from "../../store/thunks/applicationThunks";
import {apiInstance} from "../../api";

export const Account = () => {
    const [accordeonOpened, setAccordeonOpened] = useState(false);
    const { theme, changeTheme } = useContext(ThemeContext);
    // const [image, setImage] = useState(null);
    // const pictureSrc = image ? URL.createObjectURL(image) : LoadImage;
    const [openedModal, setOpenedModal] = useState(false);
    const dispatch = useDispatch()
    const user = useSelector(getUser)

    const [profileForm, setProfileForm] = useState({})
    const [notificationForm, setNotificationForm] = useState({})

    useEffect(() => {
        if (!user) return

        const {notifications, ...rest} = user
        setProfileForm(rest)
        setNotificationForm(notifications)
        // setImage(rest.profilePhoto)
    }, [user]);


    const changeFormVal = (formName, key, value) => {
        if (formName === 'profile') {
            setProfileForm(prevState => ({...prevState, [key]: value}))
        }
        if (formName === 'notification') {
            setNotificationForm(prevState => ({...prevState, [key]: value}))
        }
    }

    const [disabledField, setDisabledField] = useState({})


    const sendFormData = async (formType, fieldName, currentVal) => {
        if (formType === 'profile') {
            if (user && user[fieldName] === currentVal) return
            setDisabledField(prevState => ({...prevState, [fieldName]: true}))
            const res = await dispatch(fetchUpdateProfileThunk({[fieldName]: currentVal}))
            setDisabledField(prevState => ({...prevState, [fieldName]: false}))
        }
        if (formType === 'notification') {
            if (user && user.notifications[fieldName] === currentVal) return
            setDisabledField(prevState => ({...prevState, [fieldName]: true}))
            const res = await dispatch(fetchUpdateProfileNotificationsThunk({[fieldName]: currentVal}))
            setDisabledField(prevState => ({...prevState, [fieldName]: false}))
        }
    }

    const sendPicture = async (image) => {
        const formData = new FormData();
        formData.append('profilePhoto', image);

        setDisabledField(prevState => ({...prevState, 'profilePhoto': true}))
        try {
            // TODO переписать нормально
            const res = await apiInstance.put(`https://oshen.webstaginghub.com/api/users/me`, formData, { headers: {'Content-Type': 'multipart/form-data'}})
            
            if (res.data.success) {
                setProfileForm(prevState => ({...prevState, profilePhoto: res.data.data.profilePhoto}))
            }

        } catch (e) {

        }
        setDisabledField(prevState => ({...prevState, 'profilePhoto': false}))

    };

    const formatLabel = (inputString) => {
        if (inputString.startsWith('is')) {
            return inputString.charAt(2).toUpperCase() + inputString.slice(3);
        } else {
            return inputString.charAt(0).toUpperCase() + inputString.slice(1);
        }
    }

    const handleLogout = async () => {
        dispatch(logoutThunk());
    }

    return (
        <div className="dashboard-layout">
            <header className="flex gap-5 justify-between pb-[32px] w-full border-b border-solid border-b-[color:var(--black-25,rgba(11,11,11,0.25))] max-md:flex-wrap max-md:max-w-full dark:border-b-[#ffffff40]">
                <div className="flex gap-2 justify-between max-md:flex-wrap max-md:max-w-full">
                    <div className="flex flex-col flex-1 justify-center text-neutral-950">
                        <div className="text-[28px] leading-[36px] font-bold tracking-wider whitespace-nowrap text-[#0B0B0B] dark:text-white">Settings</div>
                    </div>
                </div>
            </header>
            <div className="dashboard-layout__content">
                <div className="flex flex-col">
                    <div className="flex flex-wrap gap-5 py-[24px] border-b border-solid border-b-[color:var(--black-25,rgba(11,11,11,0.25))] dark:border-b-[#ffffff40]">
                        <div className="flex-1 flex flex-col justify-center max-2xl:flex-none max-2xl:w-[40%]">
                            <div className="flex flex-col gap-[8px]">
                                <div className="text-[20px] leading-[26px] font-[600] text-[#0B0B0B] dark:text-white">Profile</div>
                                <div className="text-[12px] leading-[15px] font-[400] text-[#0B0B0B] dark:text-white">
                                    Change settings related to your profile including your display name and profile picture.
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 flex items-center mx-[-25px] max-2xl:flex-none max-2xl:w-[60%]">
                            <div className="px-[25px] max-w-max min-w-[220px] flex flex-col border-r border-solid border-[#0B0B0B1A] dark:border-r-[#ffffff10]">
                                <form method="post" className="flex flex-col gap-[12px] max-w-max">
                                    <div className="rounded-[360px] overflow-hidden w-[120px] h-[120px] mx-auto bg-[#ccc]">
                                        <img src={profileForm?.profilePhoto || LoadImage} alt="profile pic" className="w-full h-full object-cover" />
                                    </div>
                                    <div className="flex flex-col">
                                        <label className="flex flex-col">
                                            <input type="file" hidden accept='image/*'
                                                disabled={disabledField['profilePhoto']}
                                                onChange={(e) => {
                                                    const img = e.target.files.item(0);
                                                    if(!img) return;
                                                    // setImage(img);
                                                    sendPicture(img)
                                                    // changeFormVal('profile', 'profilePhoto', img)
                                                    // sendFormData('profile','profilePhoto', img)
                                                }}
                                            />
                                            <div className="btn btn-custom">
                                                <span className="ico --size-md">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.66667 16.4444H17.3333L14 12L11.3333 15.5556L9.33333 12.8889L6.66667 16.4444ZM4 20V4H20V20H4Z" fill="currentColor"></path>
                                                    </svg>
                                                </span>
                                                <span className="text-[12px] leading-[15px] font-[600]">Change Profile Picture</span>
                                            </div>
                                        </label>

                                    </div>
                                </form>
                            </div>
                            <div className="px-[25px] flex-auto flex flex-col">
                                <div className="flex flex-wrap xl:mx-[-24px] mx-[-12px] gap-y-[12px]">
                                    <div className="flex flex-col gap-[8px] w-[50%] max-w-[50%] xl:px-[24px] px-[12px] justify-between">
                                        <div className="font-semibold text-[#0B0B0B] dark:text-white">First Name</div>
                                        <label className="input">
                                            <input type="text" className="flex-auto" disabled={disabledField['firstName']} placeholder="First Name"
                                                   value={profileForm.firstName || ''}
                                                   onBlur={() => sendFormData('profile','firstName', profileForm.firstName)}
                                                   onChange={(e) => {changeFormVal('profile', 'firstName', e.target.value)}}
                                            />
                                        </label>
                                    </div>
                                    <div className="flex flex-col gap-[8px] w-[50%] max-w-[50%] xl:px-[24px] px-[12px] justify-between">
                                        <div className="font-semibold text-[#0B0B0B] dark:text-white">Email Address</div>
                                        <label className="input">
                                            <input type="text" className="flex-auto" placeholder="Email" value={profileForm.email|| ''} disabled />
                                        </label>
                                    </div>
                                    <div className="flex flex-col gap-[8px] w-[50%] max-w-[50%] xl:px-[24px] px-[12px] justify-between">
                                        <div className="font-semibold text-[#0B0B0B] dark:text-white">Last Name</div>
                                        <label className="input">
                                            <input type="text" className="flex-auto" placeholder="Laverack"
                                                   value={profileForm.lastName|| ''}
                                                   disabled={disabledField['lastName']}
                                                   onBlur={() => sendFormData('profile','lastName', profileForm.lastName)}
                                                   onChange={(e) => {changeFormVal('profile', 'lastName', e.target.value)}}
                                            />
                                        </label>
                                    </div>
                                    <div className="flex flex-col gap-[8px] w-[50%] max-w-[50%] xl:px-[24px] px-[12px] justify-between">
                                        <div className="font-semibold text-[#0B0B0B] dark:text-white">Dark Mode</div>
                                        <label className="flex items-center gap-[10px]">
                                            <input type="checkbox" hidden
                                                   checked={profileForm.isDarkMode || false}
                                                   disabled={disabledField['isDarkMode']}
                                                   onChange={(e) => {changeTheme();changeFormVal('profile', 'isDarkMode', !profileForm.isDarkMode); sendFormData('profile','isDarkMode', !profileForm.isDarkMode)}}
                                            />
                                            <div className="checker-switch">
                                                <div className="checker-switch-switch-container">
                                                    <span className="switch"></span>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap gap-5 py-[24px] border-b border-solid border-b-[color:var(--black-25,rgba(11,11,11,0.25))] dark:border-b-[#ffffff40]">
                        <div className="flex-1 flex flex-col justify-center max-2xl:flex-none max-2xl:w-[40%]">
                            <div className="flex flex-col gap-[8px]">
                                <div className="text-[20px] leading-[26px] font-[600] text-[#0B0B0B] dark:text-white">Notifications</div>
                                <div className="text-[12px] leading-[15px] font-[400] text-[#0B0B0B] dark:text-white">
                                    Choose how you would like to receive notifications for mission updates.
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 flex items-center mx-[-25px] max-2xl:flex-none max-2xl:w-[60%]">
                            <div className="px-[25px] flex-auto flex flex-col gap-[10px]">
                                <div className="flex flex-wrap items-center gap-x-[40px] gap-y-[20px]">
                                    {notificationForm && Object.entries(notificationForm).slice(0,3).map((el,idx) => <label key={idx} className="flex items-center gap-[10px]">
                                        <div
                                            className="font-semibold text-[#0B0B0B] dark:text-white text-[12px] overflow-hidden text-ellipsis">{formatLabel(el[0])}
                                        </div>
                                        <input type="checkbox" hidden checked={el[1]}
                                               disabled={disabledField[el[0]]}
                                               onChange={() => {
                                                   sendFormData('notification', el[0], !el[1])
                                                   changeFormVal('notification', el[0], !el[1]);
                                               }}/>
                                        <div className="checker-switch">
                                            <div className="checker-switch-switch-container">
                                                <span className="switch"></span>
                                            </div>
                                        </div>
                                    </label>)}

                                </div>
                                <div className="flex flex-col gap-[10px] toggle-element">
                                    <button 
                                        type="button" 
                                        className={classNames("btn toggle-element-btn max-w-max px-0", {
                                            '--show-toggle': accordeonOpened
                                        })}
                                        onClick={() => setAccordeonOpened(!accordeonOpened)}
                                    >
                                        <p className="text-[16px]">Sensor Specific Alerts</p>
                                        <span className="ico --size-md ico-arrow">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 9L11.97 15L8 9L16 9Z" fill="CurrentColor"></path>
                                            </svg>
                                        </span>
                                    </button>
                                    <div className="toggle-element--body" style={{ 
                                            boxSizing: 'border-box',
                                            display: 'block',
                                            transitionProperty: 'height',
                                            transitionDuration: '300ms',
                                            height: accordeonOpened ? 76 : 0,
                                            overflow: 'hidden',
                                        }}>
                                        <div className="flex flex-wrap items-center gap-y-[20px] mx-[-15px]">
                                            {notificationForm && Object.entries(notificationForm).slice(3).map((el,idx) => <label key={idx} className="flex items-center gap-[10px] max-w-[20%] w-[20%] px-[15px] justify-between overflow-hidden">
                                                <div className="font-semibold text-[#0B0B0B] dark:text-white text-[12px] overflow-hidden text-ellipsis">{formatLabel(el[0])}</div>
                                                <input type="checkbox" hidden checked={el[1]}
                                                       disabled={disabledField[el[0]]}
                                                       onChange={() => {
                                                           changeFormVal('notification', el[0], !el[1])
                                                           sendFormData('notification',el[0], !el[1])
                                                       }} />
                                                <div className="checker-switch">
                                                    <div className="checker-switch-switch-container">
                                                        <span className="switch"></span>
                                                    </div>
                                                </div>
                                            </label>)}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap gap-5 py-[24px]">
                    <div className="flex-1 flex flex-col justify-center max-2xl:flex-none max-2xl:w-[40%]">
                        <div className="flex flex-col gap-[8px]">
                            <div className="text-[20px] leading-[26px] font-[600] text-[#0B0B0B] dark:text-white">Security</div>
                            <div className="text-[12px] leading-[15px] font-[400] text-[#0B0B0B] dark:text-white">
                                Change your password and toggle 2-factor authentication.
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 flex items-center mx-[-25px] max-2xl:flex-none max-2xl:w-[60%]">
                        <div className="px-[25px] flex-auto flex flex-col gap-[10px]">
                            <div className="flex flex-col gap-[15px] max-w-max">
                                <div className='flex items-center gap-3'>
                                    <button onClick={() => setOpenedModal(true)}  className="btn btn-custom-outline max-w-max">
                                        <span className="ico --size-md">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.00004 13.846C7.51338 13.846 7.94938 13.6667 8.30804 13.308C8.66671 12.9487 8.84604 12.5127 8.84604 12C8.84604 11.4874 8.66671 11.0514 8.30804 10.692C7.94871 10.3334 7.51271 10.154 7.00004 10.154C6.48738 10.154 6.05138 10.3334 5.69204 10.692C5.33338 11.0514 5.15404 11.4874 5.15404 12C5.15404 12.5127 5.33338 12.9487 5.69204 13.308C6.05138 13.6667 6.48738 13.846 7.00004 13.846ZM7.00004 16.731C5.66671 16.731 4.54504 16.2757 3.63504 15.365C2.72438 14.455 2.26904 13.3334 2.26904 12C2.26904 10.6667 2.72438 9.54504 3.63504 8.63504C4.54504 7.72438 5.66671 7.26904 7.00004 7.26904C8.13204 7.26904 9.11238 7.58838 9.94104 8.22704C10.7704 8.86571 11.3017 9.62338 11.535 10.5H20.583L22.077 11.994L19.597 14.629L17.769 13.25L15.942 14.692L14.385 13.5H11.535C11.2977 14.392 10.7617 15.1537 9.92704 15.785C9.09238 16.4157 8.11671 16.731 7.00004 16.731Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <span className="text-[12px] leading-[15px] font-[600]">Change Password</span>
                                    </button>
                                    <PanelButton 
                                        className='w-fit px-3'
                                        onClick={handleLogout}
                                    >
                                        Log Out
                                    </PanelButton>
                                </div>
                                
                                <label className="flex items-center gap-[10px] justify-between overflow-hidden">
                                    <div className="font-semibold text-[#0B0B0B] dark:text-white text-[12px] overflow-hidden text-ellipsis">
                                        Enable 2-Factor Authentication
                                    </div>
                                    <input type="checkbox" hidden
                                           disabled={disabledField['twoFactorAuth']}
                                           checked={profileForm.twoFactorAuth || false}
                                           onChange={(e) => {changeFormVal('profile', 'twoFactorAuth', !profileForm.twoFactorAuth); sendFormData('profile','twoFactorAuth', !profileForm.twoFactorAuth)}}/>
                                    <div className="checker-switch">
                                        <div className="checker-switch-switch-container">
                                            <span className="switch"></span>
                                        </div>
                                    </div>
                                </label>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openedModal && <ChangePasswordModal
                close={() => setOpenedModal(null)}
                type={openedModal}
            />}
        </div>
    );
}

export default Account;
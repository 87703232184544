import {useCallback, useEffect, useLayoutEffect, useState} from "react"
import { MapSideMenu } from "../../components/MapSideMenu"
import MissionPlanerControls from "../../components/MissionPlanerControls"
import TimelineSimulation from "../../components/TimelineSimulation"
// import { LocationDataModal } from "../Modals/LocationDataModal"
// import { SelectAMissionModal } from "../Modals/SelectAMissionModal"
import '@elfalem/leaflet-curve'
import 'leaflet-rotatedmarker'
import "leaflet-routing-machine"
import "leaflet-routing-machine/dist/leaflet-routing-machine.css"
import { flushSync } from 'react-dom'
import { createRoot } from 'react-dom/client'
import { ReactComponent as BinIcon } from "../../assets/img/icons/bin.svg"
import indicatorIcon from "../../assets/img/icons/indicator.svg"
import { ReactComponent as AnchorIcon } from "../../assets/img/MapAnchorwithNumberWhite.svg"
import { ReactComponent as PinIcon } from "../../assets/img/MapPinwithNumberWhite.svg"
// import InputBox from "../../components/base/InputBox"
import haversine from 'haversine-distance'
import { useDispatch, useSelector } from "react-redux"
import api from '../../api'
import { MapSideTable } from "../../components/MapSideTable"
import { statusButtons } from "../../components/parts/StatusButtons"
import { geoUtilAngle } from "../../helpers/leafletGeometryUtil"
import { convertToDegreesMinutes } from "../../helpers/utils"
import ConfirmationModal from "../../Modals/ConfirmationModal"
import MissionModal from "../../Modals/MissionModal"
import { getAllMissions } from "../../store/slices/missionSlice"
import { getSocket } from "../../store/slices/shipsSocket"
import { fetchAddWayPoints, fetchAllMission, fetchMissionWayPoints } from "../../store/thunks/fleetThunks"
import { useBeforeUnload, useBlocker } from "react-router-dom"
import SateliteMessagesModal from "../../Modals/SateliteMessagesModal"


import TimelineSimulationNew from "../../components/TimelineSimulationNew";
import PopupContent from "./components/PopupContent"
import { convertLatLngToApiFormat } from "./utils"
import chroma from "chroma-js"
import { groupByWaypoint } from "../../components/ExplorerMap"
import {validationConfig} from "./validationConfig";

const L = window.L;

const PinWithNumber = ({ number, isHistorical, type }) => {
    return <div className={`relative size-[48px] ${isHistorical ? "text-[#343C44]" : "text-[#3477B5]"}`}>
        {type === "station_keeping" ? <AnchorIcon /> : <PinIcon />}
        <span className={`absolute top-[3px] left-1/2 -translate-x-1/2 text-[16px] font-semibold ${isHistorical ? "text-[#343C44]" : "text-[#3477B5]"}`}>{number}</span>
    </div>
}

const createPathIcon = (number, isHistorical, type) => {
    const html = document.createElement('div');
    const root = createRoot(html);
    flushSync(() => {
        root.render(<PinWithNumber number={number} isHistorical={isHistorical} type={type} />);
    });
    const icon = L.divIcon({
        // iconUrl: indicatorIcon,
        iconSize: [48, 48],
        // // className: '!rotate-[-72.44deg]'
        iconAnchor: [24, 48],
        html: html.innerHTML
        // popupAnchor: [-3, -76],
        // shadowUrl: 'my-icon-shadow.png',
        // shadowSize: [68, 95],
        // shadowAnchor: [22, 94]
    });
    return icon;
}




const options = {
    // Required: API key
    key: process.env.REACT_APP_WINDY_KEY, // REPLACE WITH YOUR KEY !!!

    // Put additional console output
    verbose: false,

    // Optional: Initial state of the map
    // lat: 50.42111324828017,
    // lon: -0.8530883956700565,
    lat: 36.772615760597375,
    lon: -121.92705644472346,
    zoom: 9,
    overlay: 'wind',
    hideOverlay: true,
    graticule: false,
    latlon: false,
    englishLabels: false,
    favOverlays: [],
    level: 'surface',
    particlesAnim: 'intensive',
    // isolines: [
    //     // "off",
    //     "pressure",
    //     // "gh",
    //     // "temp"
    // ],
    isolines: 'off'
};

const missionPoints = [
    {
        latlng: [50.61189138135642, -2.1670532226562504],
        command: ''
    }
]

const MissionPlanner = ({ missionWayPoints, missionId }) => {
    const dispatch = useDispatch()
    const [openedModal, setOpenedModal] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [sateliteMessagesModalOpen, setSateliteMessagesModalOpen] = useState(false);
    const [acceptExit, setAcceptExit] = useState(false);

    const socket = useSelector(getSocket)

    const allMissions = useSelector(getAllMissions);
    const currentMission = allMissions?.find(item => +item?.id === +missionId);
    const [historicaMissionIdsForShow, setHistoricaMissionIdsForShow] = useState([])
    const [shownHistoricalWaypoints, setShownHistoricalWaypoints] = useState([])
    const [wayPoints, setWayPoints] = useState(missionWayPoints || []);
    const [addWayPointsMode, setAddWayPointsMode] = useState(false);
    const [addStationPointsMode, setAddStationPointsMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [allWayPoints, setAllWayPoints] = useState([]);

    // sliderPercentage вырезан с нового компонента TimelineSimulationNew, регулировка windi будет на других ключах
    const [sliderPercentage, setSliderPercentage] = useState(0);
    const [sliderDatePercentage, setSliderDatePercentage] = useState(0);
    const [vesselMarker, setVesselMarker] = useState(null);
    const [vesselData, setVesselData] = useState([]);
    const [fleet, setFleet] = useState([]);



    const addWayPointServer = async (obj, arrayData = false) => {
        setIsLoading(true);
        const res = await dispatch(fetchAddWayPoints({
            missionId,
            body: {
                waypoints: arrayData ? obj : [obj]
            }
        })).unwrap()

        const id = res && res?.length ? res[0]?.id : null
        setTimeout(() => { setIsLoading(false) }, 100)

        const waypoints = res?.map((point, index) => ({ ...point, ...obj[index] })) || []

        setWayPoints(prev => (
            [...prev,
            ...(arrayData
                ? waypoints
                : [
                    {
                        ...obj,
                        id: id
                    }
                ])
            ]
        ))
        // setAddWayPointsMode(false)
        // setAddStationPointsMode(false)
        setTimeout(() => {
            if (arrayData) {
                obj.forEach(item => {
                    addMarker(item.lat, item.lon, item.type);
                })
            } else {
                addMarker(obj.lat, obj.lon, obj.type);
            }
        }, 10)

    }

    const createWayPoint = (coord) => {
        if (!window.addWayPointsMode && !window.addStationPointsMode) return;
        const { lat, lng } = coord.latlng;

        const obj = {
            "lat": lat,
            "lon": lng,
            "title": "Test",
            "missionId": missionId,
            type: window.addStationPointsMode ? "station_keeping" : null,
            targetRadius: 1,
            isReached: false,
            index: window.wayPoints.length
        }
        addWayPointServer(obj);
    }

    const isCurrentPoint = (latlng1, latlng2) => {
        const { lat: lat1, lng: lng1 } = latlng1
        const { lat: lat2, lng: lng2 } = latlng2
        const tolerance = 1e-4;

        return Math.abs(+lat1 - +lat2) < tolerance && Math.abs(+lng1 - +lng2) < tolerance
    }
    const isNearPoint = (latlng1) => {
        const { lat: lat1, lng: lng1 } = latlng1;
        wayPoints.some(point => {
            const lat2 = point.lat
            const lng2 = point.lng || point.lon
            const radius = point.targetRadius
            const toRad = (value) => value * Math.PI / 180;
            const R = 6371e3;
            const φ1 = toRad(lat1);
            const φ2 = toRad(lat2);
            const Δφ = toRad(lat2 - lat1);
            const Δλ = toRad(lng2 - lng1);

            const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                Math.cos(φ1) * Math.cos(φ2) *
                Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            const distance = R * c;

            return distance <= radius;
        })
    };

    const updateWayPointsState = (map, updatedWaypoint) => {
        const currentPoint = window.wayPoints.find(point => point.id === updatedWaypoint.id)
        const oldLatLng = { lat: currentPoint.lat, lng: currentPoint.lng }

        map.eachLayer(layer => {
            if (layer instanceof L.Marker) {
                if (isCurrentPoint(layer._latlng, oldLatLng)) {
                    layer.setLatLng({ lat: updatedWaypoint.lat, lng: updatedWaypoint.lng })
                }

            }
        });
        window.wayPoints = window.wayPoints.map(point => {
            if (point.id === updatedWaypoint.id) {
                return updatedWaypoint
            }
            return point;
        });

        setWayPoints(window.wayPoints);
    }
    const deleteWayPointFromState = async(waypoint) => {
        console.log(window.wayPoints, 'hello')
       
        window.wayPoints = window.wayPoints.filter(point => point.id !== waypoint.id).map((point, index) => ({
            ...point,
            index
        }));
        const pointToUpdate = window.wayPoints.find(point => point.repeat_trajectory === waypoint.index+1)
        if(pointToUpdate) {
            const res = await api.fleet.updateWayPoints({

                missionId,
                body: {
                    waypoints: [
                        {...pointToUpdate, repeat_trajectory: null}
                    ]
                }
            });
            console.log(res, 'RESULTs')
            
        }
        console.log(window.wayPoints)
        
    
        setWayPoints(window.wayPoints)
    }
    const drawCurves = (map, waypoints, isHistorical) => {
        if (waypoints && waypoints.length > 1) {
            const mainPath = waypoints.flatMap((el, idx) => {
                if (idx === 0) {
                    return ['M', [el.lat, el.lng || el.lon]];
                }
                return ['L', [el.lat, el.lng || el.lon]];
            });

            L.curve(mainPath, { color: isHistorical ? "#343C44" : "#3477B5", fill: false, dashArray: "5 5" })?.addTo(map);

            waypoints.forEach(el => {
                if (el.repeat_trajectory) {
                    const loopToIndex = waypoints.findIndex(wp => wp.number === parseInt(el.repeat_trajectory));
                    if (loopToIndex >= 0) {
                        const loopToWaypoint = waypoints[loopToIndex];
                        const loopLine = ['M', [el.lat, el.lng || el.lon], 'L', [loopToWaypoint.lat, loopToWaypoint.lng || loopToWaypoint.lon]];

                        L.curve(loopLine, { color: isHistorical ? "#ff000080" : "red", fill: false, dashArray: "5 10" })?.addTo(map);
                    }
                }
            });
        }
    };
    const drawCircle = (map, wayPoint, isHistorical) => {
        L.circle([wayPoint.lat, wayPoint.lng || wayPoint.lon], {
            color: isHistorical ? "#343C44" : "#3477B5",
            fillColor: isHistorical ? "#343C44" : "#3477B5",
            fillOpacity: 0.3,
            radius: wayPoint.targetRadius
        }).addTo(map);
    }
    const removeCirlce = (map, wayPoint) => {
        map.eachLayer(layer => {
            if (!(layer instanceof L.Circle)) return
            const isCurrent = isCurrentPoint(layer._latlng, { lat: wayPoint.lat, lng: (wayPoint.lng || wayPoint.lon) })
            if (isCurrent) {
                map.removeLayer(layer);
                layer.remove()
            }
        });
    }
    const removeAndRedrawCurves = (map, waypoints) => {
        map.eachLayer(layer => {
            if (L.Curve && layer instanceof L.Curve) {
                map.removeLayer(layer);
                layer.remove()
            }
        });
        drawCurves(map, window.wayPoints);
        if (waypoints && wayPoints.length) {
            drawCurves(map, waypoints, true);
        }
    };
    const deleteWayPoint = async (e, map, waypoint, marker) => {
        const res = await api.fleet.deleteWayPoint(waypoint.id);
        if (res.success) {
            map.removeLayer(marker);
            console.log('12312321')
            await deleteWayPointFromState(waypoint)
            removeCirlce(map, waypoint)
            removeAndRedrawCurves(map);
        }
    }
    const repeatTrajectory = async (e, map, waypoint, marker, renderPopupContent) => {
        const currentPoint = window.wayPoints.find(point => point.id === waypoint.id)
        const res = await api.fleet.updateWayPoints({

            missionId,
            body: {
                waypoints: [
                    {
                        ...currentPoint,
                        lon: currentPoint.lon || currentPoint.lng,
                        id: waypoint.id,
                        repeat_trajectory: +e.target.value
                    }
                ]
            }
        });
        if (res.success && res.data && res.data.length) {
            const updatedPoint = res.data[0];
            window.wayPoints = window.wayPoints.map(point =>
                point.id === updatedPoint.id ? updatedPoint : point
            );

            removeAndRedrawCurves(map);
            renderPopupContent(updatedPoint)
        }
    };
    const updateStationDays = async (e, map, waypoint, marker, renderPopupContent) => {
        const currentPoint = window.wayPoints.find(point => point.id === waypoint.id)
        const res = await api.fleet.updateWayPoints({

            missionId,
            body: {
                waypoints: [
                    {
                        ...currentPoint,
                        lon: currentPoint.lon || currentPoint.lng,
                        id: waypoint.id,
                        stationDays: +e.target.value
                    }
                ]
            }
        });
        if (res.success && res.data && res.data.length) {
            const updatedPoint = res.data[0];
            window.wayPoints = window.wayPoints.map(point =>
                point.id === updatedPoint.id ? updatedPoint : point
            );

            // removeAndRedrawCurves(map);
            renderPopupContent(updatedPoint)
        }
    };
    const updateStationHours = async (e, map, waypoint, marker, renderPopupContent) => {
        const currentPoint = window.wayPoints.find(point => point.id === waypoint.id)
        const res = await api.fleet.updateWayPoints({

            missionId,
            body: {
                waypoints: [
                    {
                        ...currentPoint,
                        lon: currentPoint.lon || currentPoint.lng,
                        id: waypoint.id,
                        stationHours: +e.target.value
                    }
                ]
            }
        });
        if (res.success && res.data && res.data.length) {
            const updatedPoint = res.data[0];
            window.wayPoints = window.wayPoints.map(point =>
                point.id === updatedPoint.id ? updatedPoint : point
            );

            // removeAndRedrawCurves(map);
            renderPopupContent(updatedPoint)
        }
    };
    const updateTargetRadius = async (e, map, waypoint, marker, renderPopupContent) => {
        
        console.log(e.target.value, 'e target')

        const { min, max } = validationConfig.radius

        // if (e.target.value < min  || e.target.value >= max) {
        //     return
        // }
        
        const currentPoint = window.wayPoints.find(point => point.id === waypoint.id)
        const res = await api.fleet.updateWayPoints({

            missionId,
            body: {
                waypoints: [
                    {
                        ...currentPoint,
                        lon: currentPoint.lng,
                        id: waypoint.id,
                        targetRadius: +e.target.value
                    }
                ]
            }
        });
        if (res.success && res.data && res.data.length) {
            const updatedPoint = convertLatLngToApiFormat(res.data[0]);

            removeCirlce(map, waypoint)
            drawCircle(map, updatedPoint)
            renderPopupContent(updatedPoint)
            updateWayPointsState(map, updatedPoint)
            window.wayPoints = window.wayPoints.map(point =>
                point.id === updatedPoint.id ? updatedPoint : point
            );
            removeAndRedrawCurves(map, window.wayPoints)
        }
    }
    const renderPopupContent = (data, root, prevItemDataIndex, isHistorical) => {
        return flushSync(() => {
            root.render(
                <PopupContent
                    data={data}
                    prevItemData={prevItemDataIndex > 0 ? window.wayPoints[prevItemDataIndex - 1] : null}
                    isHistorical={isHistorical}
                    currentMission={currentMission}
                />
            );
        });
    };
    const renderCoordPopupContent = (latlng, root) => {

        return flushSync(() => {
            root.render(
                <div>
                    <p className="!mt-[8px] text-[12px] font-semibold text-[#0B0B0B80]">{latlng.lat}</p>
                    <p className="!mt-[4px] text-[12px] font-semibold text-[#0B0B0B80]">{latlng.lon || latlng.lng}</p>
                </div>

            );
        });
    }
    const updatePosition = async (e, latlng, map, waypoint, marker, renderPopupContent) => {
        const currentPoint = window.wayPoints.find(point => point.id === waypoint.id)
        const res = await api.fleet.updateWayPoints({

            missionId,
            body: {
                waypoints: [
                    {
                        ...currentPoint,
                        lon: currentPoint.lng,
                        id: waypoint.id,
                        ...latlng,
                    }
                ]
            }
        });
        if (res.success && res.data && res.data.length) {
            const updatedPoint = convertLatLngToApiFormat(res.data[0]);
            removeCirlce(map, currentPoint)
            drawCircle(map, updatedPoint)
            updateWayPointsState(map, updatedPoint)
            // removeAndRedrawCurves(map);
            renderPopupContent(updatedPoint)
        }
    }

    const addMarker = (lat, lng) => {
        if (!window.customWindy) return

        const map = window.customWindy.map

        let currentPoint = window.wayPoints.find(el => el.lat === lat && el.lng === lng);

        // reached test
        // currentPoint = { ...currentPoint, isReached: currentPoint.number === 1 || currentPoint.number === 2 }

        const prevItemDataIndex = window.wayPoints.findIndex(el => el.lat === lat && el.lng === lng);

        const popup = document.createElement('div');
        const root = createRoot(popup);

        const coordPopup = document.createElement('div')
        const coordRoot = createRoot(coordPopup)

        renderCoordPopupContent(currentPoint, coordRoot)
        renderPopupContent(currentPoint, root, prevItemDataIndex, currentPoint?.isReached);

        if (!currentPoint) return;
        const marker = L.marker([currentPoint.lat, currentPoint.lng], {
            icon: createPathIcon(currentPoint.number, currentPoint?.isReached, currentPoint?.type),
        }).addTo(map)
            .on('click', function (e) {
                const newPopup = L.popup({
                    offset: [0, -48],
                }).setContent(popup.innerHTML);

                if (this.getPopup()) {
                    this.closePopup();
                    this.unbindPopup();
                }

                this.bindPopup(newPopup).openPopup();

                this.getPopup().on('remove', function () {
                    marker.unbindPopup();
                });

                setTimeout(() => {
                    const deleteWaypointBtn = document.querySelector('.leaflet-pane.leaflet-popup-pane .delete-waypoint-btn');
                    deleteWaypointBtn?.addEventListener('click', async (e) => deleteWayPoint(e, map, currentPoint, marker));

                    const repeatTrajectoryInput = document.querySelector('.leaflet-pane.leaflet-popup-pane .repeat-trajectory-input');
                    const targetRadiusInput = document.querySelector('.leaflet-pane.leaflet-popup-pane .target-radius-input');
                    const stationDaysInput = document.querySelector('.leaflet-pane.leaflet-popup-pane .station-days-input');
                    const stationHoursInput = document.querySelector('.leaflet-pane.leaflet-popup-pane .station-hours-input');

                    const latInput = document.querySelector('.leaflet-pane.leaflet-popup-pane .lat-input');
                    const lngInput = document.querySelector('.leaflet-pane.leaflet-popup-pane .lng-input');
                    repeatTrajectoryInput?.addEventListener('change', async (e) => repeatTrajectory(e, map, currentPoint, marker, (updatedData) => {
                        renderPopupContent(updatedData, root, prevItemDataIndex)
                    }));
                    targetRadiusInput?.addEventListener('change', async (e) => updateTargetRadius(e, map, currentPoint, marker, (updatedData) => {
                        renderPopupContent(updatedData, root, prevItemDataIndex)
                    }));
                    stationDaysInput?.addEventListener('change', async (e) => updateStationDays(e, map, currentPoint, marker, (updatedData) => {
                        renderPopupContent(updatedData, root, prevItemDataIndex)
                    }));
                    stationHoursInput?.addEventListener('change', async (e) => updateStationHours(e, map, currentPoint, marker, (updatedData) => {
                        renderPopupContent(updatedData, root, prevItemDataIndex)
                    }));
                    latInput?.addEventListener('change', async (e) =>
                        updatePosition(e,
                            {
                                lat: +e.target.value,
                            },
                            map, currentPoint, marker,
                            (updatedData) => {
                                renderPopupContent(updatedData, root, prevItemDataIndex)
                            }));
                    lngInput?.addEventListener('change', async (e) =>
                        updatePosition(e,
                            {
                                lon: +e.target.value,
                            },
                            map, currentPoint, marker,
                            (updatedData) => {
                                renderPopupContent(updatedData, root, prevItemDataIndex)
                            }));
                }, 150);
            })
            .on("dragstart", function (e) {
                // const newPopup = L.popup({
                //     offset: [0, -48],
                // }).setContent(coordPopup.innerHTML);

                // if (this.getPopup()) {
                //     this.closePopup();
                //     this.unbindPopup();
                // }

                // this.bindPopup(newPopup).openPopup();

                // this.getPopup().on('remove', function () {
                //     marker.unbindPopup();
                // });

            })
            .on("drag", function (e) {
                const newLatLng = e.target.getLatLng();
                renderCoordPopupContent(newLatLng, coordRoot)
                const newPopup = L.popup({
                    offset: [0, -48],
                }).setContent(coordPopup.innerHTML);

                if (this.getPopup()) {
                    this.closePopup();
                    this.unbindPopup();
                }

                this.bindPopup(newPopup).openPopup();

                this.getPopup().on('remove', function () {
                    marker.unbindPopup();
                });
            })
            .on('dragend', function (e) {
                const newLatLng = e.target.getLatLng();
                updatePosition(e,
                    {
                        lat: newLatLng.lat,
                        lon: newLatLng.lng
                    },
                    map, currentPoint, marker,
                    (updatedData) => {
                        renderPopupContent(updatedData, root, prevItemDataIndex, currentPoint?.isReached)
                    })

                if (this.getPopup()) {
                    this.closePopup();
                    this.unbindPopup();
                }
            });
        if (currentPoint.targetRadius) {
            drawCircle(map, currentPoint, currentPoint?.isReached)
        }

        drawCurves(map, window.wayPoints);



    }


    const [windy, setWindy] = useState(null);

    const mapOptions = [
        {
            label: 'Wind',
            default: true,
            onSelect: () => {
                windy.store.set('overlay', 'wind')
                windy.store.set('particlesAnim', 'intensive')
            }
        },
        {
            label: 'Default',
            default: false,
            onSelect: () => {
                windy.store.set('overlay', 'temp')
                windy.store.set('particlesAnim', 'off')
            }
        },
        {
            label: 'Pressure',
            default: false,
            onSelect: () => {
                windy.store.set('overlay', 'pressure')
                windy.store.set('particlesAnim', 'on')
            }
        },
        // {
        //     label: 'Waves',
        //     default: false,
        //     onSelect: () => {
        //         windy.store.set('overlay', "waves");
        //         windy.store.set('particlesAnim', 'on');
        //     }
        // }
    ];

    const addFromCoordinatesArray = (value) => {
        if (!value?.length) {
            return false;
        }

        setAddWayPointsMode(true);

        // window.wayPoints = [...window.wayPoints, ...value.map((el, idx) => ({
        //     lat: el.lat,
        //     lng: el.lon,
        //     number: idx + 1
        // }))];

        setTimeout(() => {
            addWayPointServer(value, true);
        }, 150);
    }

    const sortWaypoints = (wayPoints) => {
        let distanceToPreviousPoint = 0;
        let result = wayPoints.map((el, idx, arr) => {
            let distance = 0;
            if (idx > 0) {
                const prevPoint = arr[idx - 1];
                distance = haversine({ latitude: el?.lat, longitude: el?.lon }, { latitude: prevPoint?.lat, longitude: prevPoint?.lon });
            }

            distanceToPreviousPoint += distance;

            return {
                lat: el.lat,
                lng: el.lon,
                number: idx + 1,
                distanceToPrevious: distanceToPreviousPoint,
                ...el,
            }
        });
        console.log(distanceToPreviousPoint)
        result = result.map(item => ({
            ...item,
            distanceToPrevious: distanceToPreviousPoint - item.distanceToPrevious
        }))
        return result   
    }

    const removeWaypointsFromMap = () => {
        if (!window.customWindy || !window.customWindy.map) {
            console.error("Windy map is not initialized");
            return;
        }
        const map = window.customWindy?.map;
        const newPoints = shownHistoricalWaypoints.filter(point =>
            historicaMissionIdsForShow.includes(point.missionId)
        );
        shownHistoricalWaypoints.forEach(waypoint => {
            if (!historicaMissionIdsForShow.includes(waypoint.missionId)) {
                map.removeLayer(waypoint.marker);
                removeCirlce(map, waypoint)
            }
        });
        setShownHistoricalWaypoints(newPoints);
        removeAndRedrawCurves(map, newPoints);
    };

    const showHistoricalWaypoints = (historicalWaypoints) => {
        if (!window.customWindy || !window.customWindy.map) {
            console.error("Windy map is not initialized");
            return;
        }

        const map = window.customWindy.map
        const sortedWaypoints = sortWaypoints(historicalWaypoints)

        sortedWaypoints.forEach(waypoint => {
            const hasElement = shownHistoricalWaypoints.some(item => item.id === waypoint.id);
            if (hasElement) return;

            const { lat, lon } = waypoint;

            const marker = L.marker([lat, lon], { icon: createPathIcon(waypoint.number, true, waypoint.type) }).addTo(map);
            const popup = document.createElement('div');
            const root = createRoot(popup);

            const prevItemDataIndexData = sortedWaypoints.findIndex(point => point.lat === lat && point.lng === lon);

            renderPopupContent(waypoint, root, prevItemDataIndexData, true);

            setShownHistoricalWaypoints(prev => {
                const waypointExists = prev.some(wp => wp.id === waypoint.id);
                if (waypointExists) {
                    return prev
                } else {
                    return [...prev, { ...waypoint, marker }];
                }
            });
            marker.on('click', function (e) {
                const newPopup = L.popup({
                    offset: [0, -48],
                }).setContent(popup.innerHTML);

                if (this.getPopup()) {
                    this.closePopup();
                    this.unbindPopup();
                }

                this.bindPopup(newPopup).openPopup();

                this.getPopup().on('remove', function () {
                    marker.unbindPopup();
                });

            });

            if (waypoint.targetRadius) {
                drawCircle(map, waypoint, true)
            }
        });
        drawCurves(map, sortedWaypoints, true);
    };

    const getAndShowOtherMissionWaypoints = async (missionId) => {
        setIsLoading(true)
        console.log('request')
        const res = await dispatch(fetchMissionWayPoints(missionId));
        if (res?.payload) {
            const waypoints = res.payload
            if (!waypoints && !waypoints.length) return;
            setTimeout(() => {
                showHistoricalWaypoints(waypoints);
                setIsLoading(false)
            }, 100);
        }

    }

    useEffect(() => {
        if (historicaMissionIdsForShow && historicaMissionIdsForShow.length) {
            console.log('hello')
            historicaMissionIdsForShow.forEach(missionId => {
                setTimeout(() => {
                    getAndShowOtherMissionWaypoints(missionId);
                }, 100);
            })
        }
        removeWaypointsFromMap()
    }, [historicaMissionIdsForShow])
    console.log(historicaMissionIdsForShow)

    useEffect(() => {
        if (!window.customWindy || !window.customWindy?.map) return;
        const map = window.customWindy.map
        setTimeout(() => {
            removeAndRedrawCurves(map, shownHistoricalWaypoints)
        }, 100)
    }, [shownHistoricalWaypoints, wayPoints]);

    useEffect(() => {
        window.wayPoints = sortWaypoints(wayPoints)
    }, [wayPoints]);


    useEffect(() => {
        window.addWayPointsMode = addWayPointsMode
        window.addStationPointsMode = addStationPointsMode
    }, [addWayPointsMode, addStationPointsMode]);


    const [testVesselData, setTestVesselData] = useState([])
    const [tableData, setTableData] = useState([])


    const addVesselWaypoint = (lat, lon) => {
        const { map } = windy;
        if (!map) return
        // var myIcon = L.icon({
        //     iconUrl: createPathIcon(null, true),
        //     iconSize: [36, 49],
        //     iconAnchor: [18, 25],
        //     shadowUrl: null
        // });
        var icon = new L.Icon.Default();
        icon.options.shadowSize = [0, 0];
        let point = L.marker([lat, lon], {
            icon
        }).addTo(map);
    }
    const [lineMarker, setLineMarker] = useState(null)

    useEffect(() => {
        if (socket && currentMission?.vesselId) {
            socket.emit('vessel-histories', {
                "vesselId": currentMission.vesselId
            })

            socket.on('vessel-histories', (data) => {
                
                const formatData = data.filter(item => item?.dataHistory?.missionId === currentMission.id).map(item => {
                    if(item?.payload) {
                        const result = {...item, ...item.payload}
                        delete result.payload
                        delete result.dataHistory
                        if(result.msg_format === 2) {
                            result.longditude = result.longitude
                        }
                        return result
                    } else {
                      return item
                    }
                })
                console.log(formatData)
                setTableData(formatData[0])
                setVesselData(formatData)
            })
            socket.on('vessel-new-waypoint', (data) => {
                if(data?.missionHistory?.missionId !== currentMission.id) {
                    return
                }
                const actual = Array.isArray(data) ? data[0] : data?.waypoint
                console.log(actual)
                const result = {...actual, ...actual.payload}
                delete result.payload
                if(result.msg_format === 2) {
                    result.longditude = result.longitude
                }
                setTableData(result)
                // setVesselData(prev => ([...prev, actual]))
                const lineMarker = window.lineMarker
                if (lineMarker) {
                    // const newAngle = geoUtilAngle(windy.map, lineMarker._latlng, {
                    //     lat: result?.target_waypoint_lat,
                    //     lng: result?.target_waypoint_long,

                    // });
                    lineMarker.setLatLng([result?.latitude, result?.longditude])
                    // lineMarker.setRotationAngle(newAngle)
                    lineMarker.setRotationAngle(result.target_heading)
                    // addVesselWaypoint(result?.latitude, result?.longditude)
                }
                const lineTrack = window.lineTrack
                if (lineTrack) {
                    
                    const layer = lineTrack.getLayers()?.[0]
                    if(layer) {
                        console.log('hello')
                        let coords = layer.getLatLngs()
                        coords = [[result?.latitude, result?.longditude], ...coords]
                        layer.setLatLngs(coords)
                    }
                }
                console.log(result, "actual");
                setVesselData(prev => [result, ...prev])
            });
        }
    }, [socket, currentMission])

    const [lineTrack, setLineTrack] = useState(null)
   
    const addVesselHistory = (map, icon) => {
        if (!vesselData?.length) return;

        if (lineMarker) return
        function groupByWaypoint(data) {
            const groupedData = {};
          
            data.forEach(item => {
              const key = item.msg_format === 1 ? `${item.target_waypoint_lat}-${item.target_waypoint_long}` : `${item.target_waypoint_latitude}-${item.target_waypoint_longitude}`;
              if (!groupedData[key]) {
                groupedData[key] = [];
              }
              groupedData[key].push(item.msg_format === 1 ? [item.latitude, item.longditude,] : [item.latitude, item.longitude,]);
            });
          
            return Object.values(groupedData);
          }
        
        const groupedData = groupByWaypoint(vesselData);
        const colors = chroma.scale(['red', 'orange', 'yellow', 'green', 'blue', 'indigo' ,'violet'])
        .mode('lch').colors(groupedData.length)
        console.log(groupedData)
        let multiSegment = L.featureGroup(groupedData.map((item, index, array) => {
            const coordinates = [...item];
            const next = array[index + 1]
            if(next) {
                coordinates.push(next[0])
            }
            return L.polyline(coordinates, {color: colors[index]});
        })).addTo(map);
        window.lineTrack = multiSegment
        console.log(multiSegment.getLayers())
        const index = 0
        // const index = vesselData.length - 1

        let vesselHistory = L.marker([vesselData[index].latitude, vesselData[index].longditude], {
            icon,
            rotationAngle: vesselData[index].target_heading,

        }).addTo(map);
        setLineMarker(vesselHistory)
        window.lineMarker = vesselHistory



        // let trackCoordinates = [
        //     [vesselData[0].latitude, vesselData[0].longditude],
        //     [vesselData[vesselData.length -1].latitude, vesselData[vesselData.length -1].longditude]
        // ];
        let trackCoordinates = [...vesselData.map(item => ([item.latitude, item.longditude]))]
        const uniquePoints = Array.from(new Set(vesselData.map(item => {
            if(item.target_waypoint_long) return item.target_waypoint_long + ',' + item.target_waypoint_lat
            return item.target_waypoint_longitude + ',' + item.target_waypoint_latitude
        }))).map(coordString => coordString.split(',').map(Number));
        uniquePoints.forEach((item, index) => {
            // if (index === vesselData.length - 1) return
            // if(isNearPoint({ lat: item.latitude, lng: item.longditude })) return
            addVesselWaypoint(item[1], item[0])
        })
        // let vesselTrack = L.polyline(trackCoordinates, {
        //     color: 'black',
        //     dashArray: '5, 10'
        // }).addTo(map);
        // setLineTrack(vesselTrack)




        map.fitBounds(multiSegment.getBounds());

    }

    const initCustomWindy = (windyAPI) => {
        // if (!vesselData.length) return

        window.customWindy = windyAPI
        setWindy(windyAPI);

        const { map, colors, picker } = windyAPI;
        colors.temp.changeColor([[203, [199, 223, 238]], [320, [199, 223, 238]]]);

        map.on('click', createWayPoint)
        map.options.minZoom = 4;
        map.options.maxZoom = 20;

        L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            "attribution": "Mapbox",
            "detectRetina": false, 
            "maxNativeZoom": 19,
            "maxZoom": 20, 
            "minZoom": 12,
            "noWrap": false,
            "opacity": 1, 
            "subdomains": "abc",
            "tms": false
        }).addTo(map);
        // var myIcon = L.icon({
        //     iconUrl: indicatorIcon,
        //     iconSize: [36, 49],
        //     iconAnchor: [18, 25],

        // });

        // let vesselMarker = L.marker([50.6112, -1.9811], {
        //     icon: myIcon,
        //     rotationAngle: 0
        // }).addTo(map);

        // historical vessel
        // addVesselHistory(map, myIcon)

        // setVesselMarker(vesselMarker);
        // map.on('click', function (e) {
        //     if (!window.addWayPointsMode && !window.addStationPointsMode) {
        //         picker.open({ lat: e.latlng.lat, lon: e.latlng.lng });
        //     }
        // });

        const popup = document.createElement('div');
        const root = createRoot(popup);

        flushSync((e) => {
            root.render(<PopupContent />);
        });
    }


    const initWindyForce = () => {
        window.windyInit(options, initCustomWindy)
    }


    useLayoutEffect(() => {
        if (windy) return;

        // if (window.customWindy) {
        //     updateCustomWindy(options)
        //     return;
        // }
        // if (!windy && !window.customWindy) {
        //     window.windyInit(options, initCustomWindy)
        // }

        initWindyForce()
    }, [windy, vesselData])


    useEffect(() => {
        if (windy && missionWayPoints?.length && wayPoints?.length !== missionWayPoints?.length) {
            // const copuMissionWayPoints = [...missionWayPoints]

            // const pointsWithDistance = copuMissionWayPoints.map((currentPoint, i, points) => {
            //     const prevPoint = points[i - 1];

            //     let distance = prevPoint
            //         ? haversine({ latitude: currentPoint?.lat, longitude: currentPoint?.lon }, { latitude: prevPoint?.lat, longitude: prevPoint?.lon })
            //         : 0;

            //     currentPoint.distanceToPrevious = prevPoint && prevPoint.distance ? prevPoint.distance + distance : distance;
            //     return currentPoint
            // })


            // setWayPoints(pointsWithDistance);

            setWayPoints(missionWayPoints);

            setAddWayPointsMode(true);

            setTimeout(() => {
                missionWayPoints.forEach((el, idx) => {
                    addMarker(el.lat, el.lon, el.type)
                })
            }, 100);



            setTimeout(() => {
                setAddWayPointsMode(false);
            }, 150);
        }
    }, [missionWayPoints, windy]);



    // useEffect(() => {
    //     if (allWayPoints?.length) {
    //         const map = windy.map;

    //         let percentageIndex = allWayPoints.length - 1
    //         // let percentageIndex = sliderPercentage === 100 ? 999 : (sliderPercentage - (sliderPercentage > 0 ? 1 : 0)) * 10;
    //         var myIcon = L.icon({
    //             iconUrl: indicatorIcon,
    //             iconSize: [36, 49],
    //             iconAnchor: [18, 25],

    //         });
    //         let vesselMarker = L.marker([50.6112, -1.9811], {
    //             icon: myIcon,
    //             rotationAngle: 0
    //         }).addTo(map);
    //         if (allWayPoints[percentageIndex]?.lat && allWayPoints[percentageIndex]?.lng) {
    //             const newLatLng = new L.LatLng(allWayPoints[percentageIndex]?.lat, allWayPoints[percentageIndex]?.lng);
    //             const newAngle = geoUtilAngle(map, vesselMarker._latlng, newLatLng);


    //             vesselMarker.setRotationAngle(newAngle);
    //             vesselMarker.setLatLng(newLatLng);
    //         }
    //     }

    // }, [vesselMarker, sliderPercentage, allWayPoints]);

    useEffect(() => {
        if (vesselData?.length && windy?.map) {
            const map = windy.map;

            // let percentageIndex = vesselData.length - 1
            // // let percentageIndex = sliderPercentage === 100 ? 999 : (sliderPercentage - (sliderPercentage > 0 ? 1 : 0)) * 10;
            var myIcon = L.icon({
                iconUrl: indicatorIcon,
                iconSize: [36, 49],
                iconAnchor: [18, 25],
                shadowUrl: null

            });
            // let vesselMarker = L.marker([vesselData[percentageIndex]?.latitude, vesselData[percentageIndex]?.longditude], {
            //     icon: myIcon,
            //     rotationAngle: 0
            // }).addTo(map);

            addVesselHistory(map, myIcon)

            // setTimeout(() => {
            //     vesselData.forEach((el, idx) => {
            //         addMarker(el.latitude, el.longditude)
            //     })
            // }, 100);

            // let trackCoordinates = [...vesselData.map(item => ([item.latitude, item.longditude]))]
            // let vesselTrack = L.polyline(trackCoordinates, {
            //     color: 'black',
            //     dashArray: '5, 10'
            // }).addTo(map);

            // if (vesselData[percentageIndex]?.latitude && vesselData[percentageIndex]?.longditude) {

            //     const newLatLng = new L.LatLng(vesselData[percentageIndex]?.target_waypoint_lat, vesselData[percentageIndex]?.target_waypoint_long);
            //     const newAngle = geoUtilAngle(map, vesselMarker._latlng, newLatLng);


            //     vesselMarker.setRotationAngle(newAngle);
            //     // vesselMarker.setLatLng(newLatLng);
            // }
        }

    }, [vesselMarker, sliderPercentage, vesselData]);

    const getFleet = async () => {
        const res = await api.fleet.getAllVessel()
        if (res?.data) {
            setFleet(res.data)
        }
    }

    useEffect(() => {
        getFleet()
    }, [])

    useBeforeUnload((event) => {
        if (!acceptExit) event.preventDefault()
    });
    const handleBlock = useCallback(() => {
        // currentLocation.pathname !== nextLocation.pathname
        if (!acceptExit) {
            setConfirmModalOpen(true)
            return true
        } else {
            return false
        }
    }, [acceptExit]);
    let blocker = useBlocker(handleBlock);

    useEffect(() => {
        if (blocker.state === 'blocked' && !acceptExit) {
            // blocker.reset();
        } else if (blocker.state === 'blocked' && acceptExit) {
            blocker.proceed();
        }
    }, [blocker, acceptExit]);

    useEffect(() => {
        return () => {
            setConfirmModalOpen(true)
        }
    }, [])

    const handleConfirm = () => {
        setAcceptExit(true)
        setConfirmModalOpen(false)
        // typeof blocker.proceed === "function" && blocker.proceed()
    }
    const handleSetAddWayPointsMode = (enable) => {
        setAddWayPointsMode(enable)
    }
    const handleAddStationPointsMode = (enable) => {
        setAddStationPointsMode(enable)
    }


    const isReachedLayer = (layer) => {
        return wayPoints.some(point => isCurrentPoint(layer._latlng, convertLatLngToApiFormat(point)) && point.id === 392
        )
    }
    useEffect(() => {
        if (!window.customWindy?.map) return
        const map = window.customWindy.map
        setTimeout(() => {
            if (addStationPointsMode || addWayPointsMode) {
                map.eachLayer(layer => {
                    if (layer instanceof L.Marker) {
                        if (isReachedLayer(layer)) return;
                        layer.dragging.enable()
                    }
                });
            } else if (!(addStationPointsMode && addWayPointsMode)) {
                map.eachLayer(layer => {
                    if (layer instanceof L.Marker) {
                        layer.dragging.disable()
                    }
                });
            }
        }, 100)
    }, [addStationPointsMode, addWayPointsMode, wayPoints])

    return (
        <div className="h-screen">
            {isLoading && <div className='cover-map-loading'></div>}
            <div className="fixed top-[10px] left-[10px] z-10">
                <MapSideMenu />
                <button
                    onClick={() => setSateliteMessagesModalOpen(true)}
                    className="group flex items-center w-full mt-6 justify-center p-[4px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917]">
                    View satellite messages
                </button>
                <MapSideTable tableData={tableData} />
            </div>

            <div className="fixed top-[10px] right-[10px] z-10 flex gap-2 pointer-events-none">
                {
                    currentMission?.status &&
                    <div className="flex items-start h-fit h-fit-bg">
                        {statusButtons[currentMission.status]}
                    </div>
                }
                <MissionPlanerControls
                    missionId={missionId}
                    addWayPointsMode={addWayPointsMode}
                    handleSetAddWayPointsMode={(enable) => handleSetAddWayPointsMode(enable)}
                    handleAddStationPointsMode={(enable) => handleAddStationPointsMode(enable)}
                    currentMissionStatus={currentMission?.status}

                    setAddWayPointsMode={(val) => setAddWayPointsMode(val)}
                    addStationPointsMode={addStationPointsMode}
                    setAddStationPointsMode={setAddStationPointsMode}
                    mapOptions={mapOptions}
                    setOpenedModal={setOpenedModal}
                    addFromCoordinatesArray={addFromCoordinatesArray}
                    historicaMissionIdsForShow={historicaMissionIdsForShow}
                    setHistoricaMissionIdsForShow={setHistoricaMissionIdsForShow}
                    wayPoints={wayPoints}
                />
            </div>

            <div className="fixed bottom-[16px] left-[20px] z-[100] w-[calc(100%-40px)]">
                {/*{currentMission && currentMission.start && currentMission.end ?*/}
                {/*    <TimelineSimulation*/}
                {/*        currentMission={currentMission}*/}
                {/*        wayPoints={wayPoints}*/}
                {/*        windy={windy}*/}
                {/*        L={L}*/}
                {/*        allWayPoints={allWayPoints}*/}
                {/*        setAllWayPoints={setAllWayPoints}*/}
                {/*        sliderPercentage={sliderPercentage}*/}
                {/*        setSliderPercentage={setSliderPercentage}*/}
                {/*    />*/}
                {/*    : null}*/}

                {currentMission  ? <TimelineSimulationNew
                    currentMission={currentMission}
                    // wayPoints={wayPoints}
                    windy={windy}
                    // L={L}
                    // allWayPoints={allWayPoints}
                    // setAllWayPoints={setAllWayPoints}
                    sliderPercentage={sliderDatePercentage}
                    setSliderPercentage={setSliderDatePercentage}
                /> : null}

            </div>

            {/* {modalData.name === 'location' && <LocationDataModal close={() => setModalData({name: ''})} openMissionModal={() => setModalData({name: 'mission'})} />}
            {modalData.name === 'mission' && <SelectAMissionModal close={() => setModalData({name: 'location'})} />} */}


            <div id="windy" className="size-full"></div>
            {openedModal !== null && <MissionModal
                missionId={missionId}
                close={() => setOpenedModal(null)}
                type={openedModal}
                onConfirm={handleConfirm}
            />}
            {
                confirmModalOpen &&
                <ConfirmationModal
                    title="Exit Without Saving?"
                    message="You have unsaved changes. Are you sure you want to exit without saving?"
                    close={() => setConfirmModalOpen(false)}
                    onConfirm={handleConfirm} />
            }
            {
                sateliteMessagesModalOpen &&
                <SateliteMessagesModal
                    data={vesselData}
                    close={() => setSateliteMessagesModalOpen(false)}
                />
            }
        </div>
    )
}

export default MissionPlanner;

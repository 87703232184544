import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';

import PictureIcon from '../../assets/img/icons/picture.svg';
import ZoomIcon from '../../assets/img/icons/zoom.svg';
import DragIcon from '../../assets/img/icons/drag.svg';
import PlusIcon from '../../assets/img/icons/plus.svg';
import MinusIcon from '../../assets/img/icons/minus.svg';
import Screen1Icon from '../../assets/img/icons/screen1.svg';
import Screen2Icon from '../../assets/img/icons/screen2.svg';
import Screen3Icon from '../../assets/img/icons/screen3.svg';
import EditIcon from '../../assets/img/icons/edit.svg';


export const LineChart = ({title='Graph Title', openModal}) => {
  const dataSourceColors = ['#caddf5', '#d0e9f8']; // Example color codes

  const chartData = {
    labels: ['12:00', '16:00', '20:00', '00:00', '04:00', '08:00'],
    datasets: [
      {
        label: 'Data Source 1',
        data: [0.9, 1.2, 3, 2.5, 2.3, 3],
        fill: false,
        borderColor: '#50b6f0',
        backgroundColor: '#caddf5',
        borderWidth: 1,
        tension: 0.2
      },
      {
        label: 'Data Source 2',
        data: [1, 2.5, 2.7, 3, 2.6, 4],        
        fill: false,
        borderColor: '#2086b4',
        backgroundColor: '#d0e9f8',
        borderWidth: 1,
        tension: 0.2
      }
    ],
    backgroundColor: dataSourceColors
  };

  const options = {
    // animations: {
    //     tension: {
    //       duration: 1000,
    //       easing: 'linear',
    //       from: 0,
    //       to: 1,
    //       loop: false
    //     }
    // },
    scales: {     
        y: {
            beginAtZero: true
        }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: "right",
            align: "start",
            labels: {
                boxWidth: 12,
                boxHeight: 12,
                fillStyle: '#caddf5',
            },
        },
    }
};

  return (
        <div className="widget-inner h-56 flex flex-col w-full">
            <div className="text-xs font-semibold tracking-wide text-neutral-950 max-md:max-w-full dark:text-white flex">
                <span className="widget-title flex items-center">
                    <span>{title}</span>
                    <button className='graph-tools ml-2' onClick={() => openModal('selectGraph')}>
                        <img src={EditIcon} className="dark-bright size-6" />
                    </button>
                </span>
                <ul className="graph-tools flex justify-end flex-1 gap-1">
                    <li>
                        <button>
                            <img src={PictureIcon} className="dark-bright size-6" />
                        </button>
                    </li>
                    <li>
                        <button>
                            <img src={ZoomIcon} className="dark-bright size-6" />
                        </button>
                    </li>
                    <li>
                        <button>
                            <img src={DragIcon} className="dark-bright size-6" />
                        </button>
                    </li>
                    <li>
                        <button>
                            <img src={PlusIcon} className="dark-bright size-6" />
                        </button>
                    </li>
                    <li>
                        <button>
                            <img src={MinusIcon} className="dark-bright size-6" />
                        </button>
                    </li>
                    <li>
                        <button>
                            <img src={Screen1Icon} className="dark-bright size-6" />
                        </button>
                    </li>
                    <li>
                        <button>
                            <img src={Screen2Icon} className="dark-bright size-6" />
                        </button>
                    </li>
                    <li>
                        <button>
                            <img src={Screen3Icon} className="dark-bright size-6" />
                        </button>
                    </li>
                </ul>
            </div>
            <div className="h-44 w-full mt-1">
                <Line data={chartData} options={options} />
            </div>
        </div>
  );
};

 
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';


export const fetchUserThunk = createAsyncThunk(
    '/get-user/',
    async (payload, { rejectWithValue, abort }) => {
        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                return abort();
            }
            const res = await api.users.getUser();
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const logoutThunk = createAsyncThunk(
    '/logout/',
    async (payload, { rejectWithValue, abort }) => {
        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                return abort();
            }
            const res = await api.auth.logout();
            if (res.success) {
                return res;
            } else {
                return abort();
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchUpdateProfileThunk = createAsyncThunk(
    '/update-profile/',
    async (payload, { rejectWithValue }) => {
        try {
            const newPayload = {
                profilePhoto: payload.photo,
                firstName: payload.firstName,
                lastName: payload.lastName,
                isDarkMode: payload.isDarkMode,
                twoFactorAuth: payload.twoFactorAuth,
            }

            const res = await api.users.updateProfile(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchUpdateProfileNotificationsThunk = createAsyncThunk(
    '/update-profile-notifications/',
    async (payload, { rejectWithValue }) => {
        console.log('ppp')
        try {
            const res = await api.users.updateNotifications(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchChangePasswordThunk = createAsyncThunk(
    '/change-password/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.users.changePassword(payload);
            return res.data
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchUploadImageThunk = createAsyncThunk(
    '/change-password/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.users.uploadImage(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
import { useState } from "react";

const InputField = ({ label, placeholder, value, onChange }) => (
    <div className="flex flex-col mt-2 dark:text-white">
        <div>{label}</div>
        <input
            className="px-2 pt-1 pb-7 mt-2 whitespace-nowrap bg-white rounded border border-solid border-neutral-950 border-opacity-30 text-notmal text-neutral-950 dark:bg-[#0B0B0B] dark:text-white"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        />
    </div>
);

const ConfigHeader = ({onClose, dashItem}) => (
    <div className="flex gap-5 justify-between px-2 py-1 w-full bg-gray-100 border-b border-solid border-neutral-950 border-opacity-10 text-neutral-950 text-opacity-50 dark:text-white dark:bg-[#151515]">
        <div>{ dashItem ? 'Edit Config' : 'New Config' }</div>
        <button onClick={onClose}>
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ddcb33ebb0cb0d777eaebba2ca6701637f967354c4f2b41114a225bd26c5a850?apiKey=9106ba04c02e4dceae488e75e2ac4862&"
                className="shrink-0 my-auto w-3 aspect-square close-icon"
            />
        </button>
    </div>
);

const ConfirmButton = ({ onClick }) => (
    <button
        className="justify-center self-end px-1 py-2 mt-2 text-white whitespace-nowrap bg-red-600 rounded border border-solid border-neutral-950 border-opacity-30"
        onClick={onClick}
    >
        Confirm
    </button>
);

const ConfigForm = ({ dashItem, onConfirm, close }) => {
    const [name, setName] = useState(dashItem?.name || "");
    const [description, setDescription] = useState(dashItem?.description || "");

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleConfirm = () => {
        // Handle form submission here
        console.log("Name:", name);
        console.log("Description:", description);
        onConfirm({ ...dashItem, name, description })
    };

  return (
    <div style={{    
            position: 'fixed',
            width: '336px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }} 
        className="flex flex-col text-xs tracking-wide rounded bg-zinc-50 max-w-[336px] border border-solid border-neutral-950 border-opacity-30 dark:border-[#ffffff1a] dark:bg-[#1b1b1b]">
        <ConfigHeader dashItem={dashItem} onClose={close}/>
        <div className="flex flex-col justify-end px-3 pb-3 mt-2 w-full font-semibold text-neutral-950">
            <InputField
                label="Name"
                placeholder="Name"
                value={name}
                onChange={handleNameChange}
            />
            <InputField
                label="Description (Optional)"
                placeholder="Description"
                value={description}
                onChange={handleDescriptionChange}
            />
            <ConfirmButton onClick={handleConfirm} />
        </div>
    </div>
  );
};

export default ConfigForm;
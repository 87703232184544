const WidgetSection = ({title, description, imgSrc, altText}) => (
	<div className="flex flex-col grow justify-between px-3 py-2 w-full text-xs tracking-wide border-l border-solid bg-zinc-50 border-neutral-950 border-opacity-30 text-neutral-950 dark:bg-[#1B1B1B] dark:text-white">
		<div className="self-center text-sm font-semibold tracking-wide">{title}</div>
		<p className="mt-1 text-center">{description}</p>
		<img loading="lazy" src={imgSrc} alt={altText} className="self-center mt-2 max-w-full rounded-sm border border-solid shadow-sm aspect-[1.04] border-red-600 border-opacity-30 w-[141px]" />
		<div className="mt-2 text-center">1x1</div>
	</div>
)
  const WidgetOptions = ({options}) => (
	<>
		{options.map((option, index) => (
			<div key={index} className="cursor-pointer audio-row justify-center py-1 px-2 border-t border-b border-solid border-neutral-950 border-opacity-10 dark:border-white dark:border-opacity-50">{option}</div>
		))}
	</>
  );
  
  const AddWidgetModal = ({close}) => {
	const options = ["Audio", "Line Graph", "Photo", "Pie Chart", "Map Widget", "Bar Chart", "Distance Travelled", "Total Waypoints"];
	const widgetData = [
	  {title: "Audio", description: "Listen to audio clips recorded from a mission.", imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/965f74f8e0861b5fad6de5acc7935ad81fdbfe1f20f0434c4e19f66d7b05760d?apiKey=9106ba04c02e4dceae488e75e2ac4862&", altText: "Audio Clips Visual Representation"},
	  // Other widgets data here...
	];
	
	return (
		<div style={{    
				position: 'fixed',
				width: '458px',
				maxWidth: '100%',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
			}}
			className="flex flex-col text-xs tracking-wide rounded bg-zinc-50 max-w-[336px] border border-solid border-neutral-950 border-opacity-30 dark:bg-[#1B1B1B]">

			<header className="flex gap-5 justify-between px-2 py-1 w-full text-xs tracking-wide bg-gray-100 border-b border-solid border-neutral-950 border-opacity-10 text-neutral-950 text-opacity-50 dark:border-[#ffffff1a] text-neutral-950 text-opacity-50 dark:text-[#ffffff80] dark:bg-[#151515]">
				<div>Add Widget</div>
				<img onClick={close} loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a70673b5920cc86d29a3f0a9e8e6af70e127ff2c1dfd87e187fc4e2bb9c00ecf?apiKey=9106ba04c02e4dceae488e75e2ac4862&" alt="Add icon" className="cursor-pointer shrink-0 my-auto w-3 aspect-square close-icon" />
			</header>

			<main className="flex flex-col justify-center px-3 pb-3 mt-2 w-full">
				<section className="justify-end rounded border border-solid border-neutral-950 border-opacity-10 dark:border-white dark:border-opacity-50">
					<div className="flex max-md:flex-col max-md:gap-0">
						<div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
							<div className="flex flex-col grow self-stretch w-full text-xs font-semibold tracking-wide bg-gray-100 text-neutral-950 text-opacity-50 dark:bg-[#151515] dark:text-white dark:text-opacity-50">
								<WidgetOptions options={options} />
							</div>
						</div>
						<div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
							{widgetData.map((widget, index) => (
								<WidgetSection key={index} title={widget.title} description={widget.description} imgSrc={widget.imgSrc} altText={widget.altText} />
							))}
							{/* Repeat the <WidgetSection /> for other data */}
						</div>
					</div>
				</section>
			</main>
			<div className="modal-footer flex justify-end items-center p-3 pt-0">
				<button onClick={close} className="justify-center self-end px-1 py-2 text-xs font-semibold tracking-wide text-white whitespace-nowrap bg-red-600 rounded border border-solid border-neutral-950 border-opacity-30">
					Confirm
				</button>
			</div>
		</div>
	);
  };
  
  export default AddWidgetModal;
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigation } from "react-router-dom";

import PanelButton from "../components/base/PanelButton";
import InputBox from "../components/base/InputBox";
import api from "../api";
import { fetchUserThunk } from "../store/thunks/applicationThunks";
import { ReactComponent as XIcon } from "../assets/img/icons/x.svg";

const ConfigHeader = ({currentForm, setCurrentForm}) => {
    const headings = {
        auth: 'Authorization',
        register: 'Register',
        login: 'Login',
        password: 'Password recovery'
    }
    return <div className="flex gap-5 justify-between px-2 py-1 w-full bg-gray-100 border-b border-solid border-neutral-950 border-opacity-10 text-neutral-950 text-opacity-50 dark:text-white dark:bg-[#151515]">
        <div>{headings[currentForm]}</div>
        {currentForm !== 'auth' && <button onClick={() => setCurrentForm('auth')}>
            <XIcon className="size-3 icon-black-50"/>
        </button>}
    </div>
};

const Auth = ({setCurrentForm}) => {
    return <>
        <PanelButton
            onClick={() => setCurrentForm('login')}
        >
            Login
        </PanelButton>
        <PanelButton
            onClick={() => setCurrentForm('register')}
        >
            Register
        </PanelButton>
    </>
}

const Login = ({setCurrentForm}) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        "email": "",
        "password": "",
        twoFactorCode: ''
    })
    const handleLogin = async () => {
        setErrorMessage(null);
        if(twoFactorAuth && !formData.twoFactorCode) {
            setErrorMessage('Two-factor authentication code is requiered');
            return;
        }
        const response = await api.auth.login(formData);
        if(!response.success) {
            setErrorMessage(response.message);
            return
        }
        if(response.twoFactorAuth) {
            setTwoFactorAuth(true);
            return;
        }
        localStorage.setItem('authToken', response.token);
        dispatch(fetchUserThunk());
    }
    const [errorMessage, setErrorMessage] = useState(null);
    const [twoFactorAuth, setTwoFactorAuth] = useState(false);
    return <>
        {twoFactorAuth ? <label>
            <div className="mb-[8px]">Enter Two-factor authorization code sent to your email</div>
            <InputBox 
                type='text'
                placeholder='Your authorization code'
                value={formData.twoFactorCode}
                onChange={(e) => setFormData((data) => ({...data, twoFactorCode: e.target.value}))}
            />
        </label> : <>
        <label>
            <div className="mb-[8px]">Email</div>
            <InputBox 
                type='email'
                placeholder='Email'
                autoComplete='email'
                value={formData.email}
                onChange={(e) => setFormData((data) => ({...data, email: e.target.value}))}
            />
        </label>
        <label>
            <div className="mb-[8px]">Password</div>
            <InputBox 
                type='password'
                placeholder='Password'
                autoComplete='current-password'
                value={formData.password}
                onChange={(e) => setFormData((data) => ({...data, password: e.target.value}))}
            />
        </label>
        </>}
        <PanelButton
            onClick={handleLogin}
        >
            Login
        </PanelButton>
        {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
        <p onClick={() => setCurrentForm('password')} className="text-red-400 mx-auto underline cursor-pointer hover:no-underline w-fit">Forgot password?</p>
    </>
}

const Register = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        "email": "",
        "password": "",
        "firstName": "",
        "lastName": ""
    })
    const handleRegister = async () => {
        setErrorMessage(null);
        const response = await api.auth.register(formData);
        if(!response.success) {
            setErrorMessage(response.message);
            return
        }
        localStorage.setItem('authToken', response.token);
        dispatch(fetchUserThunk());
    }
    const [errorMessage, setErrorMessage] = useState(null);
    return <>
        <label>
            <div className="mb-[8px]">Email</div>
            <InputBox 
                type='email'
                placeholder='Email'
                autoComplete='email'
                value={formData.email}
                onChange={(e) => setFormData((data) => ({...data, email: e.target.value}))}
            />
        </label>
        <label>
            <div className="mb-[8px]">Password</div>
            <InputBox 
                type='password'
                placeholder='Password'
                autoComplete='new-password'
                value={formData.password}
                onChange={(e) => setFormData((data) => ({...data, password: e.target.value}))}
            />
        </label>
        <label>
            <div className="mb-[8px]">First Name</div>
            <InputBox 
                type='text'
                placeholder='John'
                autoComplete='given-name'
                value={formData.firstName}
                onChange={(e) => setFormData((data) => ({...data, firstName: e.target.value}))}
            
            />
        </label> 
        <label>
            <div className="mb-[8px]">Last Name</div>
            <InputBox 
                type='text'
                placeholder='Smith'
                autoComplete='family-name'
                value={formData.lastName}
                onChange={(e) => setFormData((data) => ({...data, lastName: e.target.value}))}
            
            />
        </label> 
        <PanelButton
            onClick={handleRegister}
        >
            Register
        </PanelButton>
        {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
    </>
}

const ResetPassword = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        "email": "",
    })
    const handleRegister = async () => {
        setErrorMessage(null);
        const response = await api.auth.passwordRecoveryRequest(formData);
        setErrorMessage(response.message);
    }
    const [errorMessage, setErrorMessage] = useState(null);
    return <>
        <label>
            <div className="mb-[8px]">Your Email</div>
            <InputBox 
                type='email'
                placeholder='Email'
                autoComplete='email'
                value={formData.email}
                onChange={(e) => setFormData((data) => ({...data, email: e.target.value}))}
            />
        </label>
        <PanelButton
            onClick={handleRegister}
        >
            Request password recovery 
        </PanelButton>
        {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
    </>
}

const bannedRoutes = ['/oshen/reset-password', '/reset-password']

const AuthModal = () => {
    const [currentForm, setCurrentForm] = useState('auth');
    if(bannedRoutes.includes(window.location.pathname)) {
        return null;
    }
    const forms = {
        auth: Auth,
        register: Register,
        login: Login,
        password: ResetPassword
    }
    const Form = forms[currentForm];
    
    return <div style={{    
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
        backdropFilter: 'blur(5px)'
    }} >
        <div 
            className="flex flex-col text-xs tracking-wide rounded bg-zinc-50 w-[372px] border border-solid border-neutral-950 border-opacity-30 dark:border-[#ffffff1a] dark:bg-[#1b1b1b]"
        >
            <ConfigHeader currentForm={currentForm} setCurrentForm={setCurrentForm}/> 
            <div className="flex flex-col gap-3 px-3 pt-2 pb-3">
                <Form setCurrentForm={setCurrentForm}/>
            </div>
        </div>
    </div>
};

export default AuthModal;
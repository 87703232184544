import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../api";


export const fetchAllMission = createAsyncThunk(
    '/get-missions/',
    async (_, { rejectWithValue, abort }) => {
        try {
            const res = await api.fleet.getAllMissions();
            if (res.success) {
                return res;
            }
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchMissionById = createAsyncThunk(
    '/get-mission-by-id/',
    async (missionId, { rejectWithValue, abort }) => {
        try {
            const res = await api.fleet.getMissionById(missionId);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchCreateFleet = createAsyncThunk(
    '/create-fleet/',
    async (payload, { rejectWithValue, abort }) => {
        try {
            const res = await api.fleet.createFleet(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchCreateVessel = createAsyncThunk(
    '/create-vessel/',
    async (payload, { rejectWithValue, abort }) => {
        try {
            const res = await api.fleet.createVessel(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchCreateMission = createAsyncThunk(
    '/create-mission/',
    async (payload, { rejectWithValue, abort }) => {
        try {
            const res = await api.fleet.createMission(payload);
            if (res.success) {
                return res.data;
            } else {
                
            }
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchDeleteMission = createAsyncThunk(
    '/delete-mission/',
    async (id, { rejectWithValue, abort }) => {
        try {
            const res = await api.fleet.deleteMission(id);
            if (res.success) {
                return {id};
            }
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchMissionWayPoints = createAsyncThunk(
    '/get-waypoints/',
    async (id, { rejectWithValue, abort }) => {
        try {
            const res = await api.fleet.getWayPoints(id);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchAddWayPoints = createAsyncThunk(
    '/add-waypoints/',
    async (payload, { rejectWithValue, abort }) => {
        try {
            const res = await api.fleet.createWayPoints(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);
import React from 'react';
import { ReactComponent as XIcon } from "../assets/img/icons/x.svg";

const ConfirmButton = ({ onClick }) => (
    <button
        className="px-4 py-2 text-white bg-red-600 rounded"
        onClick={onClick}
    >
        Confirm
    </button>
);

const CancelButton = ({ onClick }) => (
    <button
        className="px-4 py-2 text-black bg-gray-200 rounded"
        onClick={onClick}
    >
        Cancel
    </button>
);

const ConfirmationModal = ({ onConfirm, title, message, close }) => {
    return (
        <div className="fixed inset-0 z-[999] flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white dark:bg-[#151515] p-6 rounded-lg shadow-lg relative">
                <button
                    className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
                    onClick={close}
                >
                    <XIcon className="size-4" />
                </button>
                <h2 className="mb-4 text-lg font-semibold text-gray-700 dark:text-white">{title}</h2>
                <p className="mb-6 text-gray-600 dark:text-gray-300">{message}</p>
                <div className="flex justify-end gap-4">
                    <CancelButton onClick={close} />
                    <ConfirmButton onClick={onConfirm} />
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;


import React from 'react';

const GraphComponent = ({ title }) => {
    const [content, setContent] = React.useState('');

    const handleContentChange = (event) => {
        setContent(event.target.value);
    };

    return (
        <div className="flex flex-col text-xs tracking-wide max-w-[328px]">
        <div className="w-full font-semibold text-neutral-950 dark:text-[#ffffff]">
            {title}
        </div>
        <input
            type="text"
            value={content}
            onChange={handleContentChange}
            className="justify-center p-2 mt-2 bg-white rounded border border-solid border-neutral-950 border-opacity-30 text-neutral-950 dark:bg-[#151515] dark:border-[#ffffff1a] dark:text-[#ffffff] dark:border-opacity-30 w-full h-[40px]"
            placeholder="Enter Content"
        />
        </div>
    );
};

const MetricCard = ({ title, unit, data }) => (
    <div className="cursor-pointer flex flex-col grow shrink-0 justify-center px-2 py-1 bg-gray-100 rounded border border-solid basis-0 border-neutral-950 border-opacity-10 w-fit dark:bg-[#151515] dark:border-[#ffffff1a] dark:text-[#ffffff] dark:border-opacity-30">
      <div className="text-neutral-950 text-opacity-50 dark:text-[#ffffff]">
        {title} <span>({unit})</span>
      </div>
      <div className="shrink-0 h-px border border-solid bg-neutral-950 bg-opacity-10 border-neutral-950 border-opacity-10"></div>
      <div className="flex gap-2 mt-1">
        {data.map((item) => (
          <div key={item.label} className={`grow justify-center px-2 py-1 text-${item.color} bg-${item.color} bg-opacity-30 rounded-[360px]`}>
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
  
const WeatherDashboard = () => {
    const yAxis1Data = [
        { label: "Wind Speed", color: "blue-500" },
        { label: "Gust Speed", color: "[#c7e2f3]" },
    ];

    const yAxis2Data = [
        { label: "Wind Direction", color: "[#b3e1ed]" },
        { label: "Measured from Wind", color: "[#c8d0e9]" },
    ];

    return (
        <div className="flex gap-3 self-stretch text-xs font-semibold tracking-wide max-md:flex-wrap py-3 mt-3 border-t border-neutral-950 border-opacity-10">
            <MetricCard title="Y Axis 1" unit="m/s" data={yAxis1Data} />
            <MetricCard title="Y Axis 2" unit="°" data={yAxis2Data} />
        </div>
    );
}


export const SelectGraphData = ({close}) => {
    return (
        <div  className="fixed top-0 left-0 w-screen h-screen  flex items-center justify-center z-10">
            <div className="back-close-ground" onClick={(e) => {e.stopPropagation();close()}}></div>
            <div className="modal-bb  w-[677px] bg-[white] dark:bg-[#1b1b1b] border border-solid border-gray-200 dark:border-[#ffffff00] rounded-[4px]">
                <div className="">
                    <div className="flex px-[8px] py-[4px] justify-between self-stretch items-center text-xs tracking-wide whitespace-nowrap bg-gray-100 border-b border-solid border-gray-200 dark:border-[#ffffff1a] text-neutral-950 text-opacity-50 dark:text-[#ffffff80] dark:bg-[#151515]">
                        <div>Select Graph Data</div>
                        <svg onClick={(e) => {e.stopPropagation();close()}} className='x-svg cursor-pointer' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.77295 8.97703C9.87862 9.0827 9.93799 9.22602 9.93799 9.37547C9.93799 9.52491 9.87862 9.66823 9.77295 9.7739C9.66728 9.87958 9.52395 9.93894 9.37451 9.93894C9.22507 9.93894 9.08175 9.87958 8.97607 9.7739L5.99998 6.79687L3.02295 9.77297C2.91728 9.87864 2.77395 9.93801 2.62451 9.93801C2.47507 9.93801 2.33175 9.87864 2.22607 9.77297C2.1204 9.6673 2.06104 9.52397 2.06104 9.37453C2.06104 9.22509 2.1204 9.08176 2.22607 8.97609L5.2031 6L2.22701 3.02297C2.12134 2.91729 2.06197 2.77397 2.06197 2.62453C2.06197 2.47509 2.12134 2.33176 2.22701 2.22609C2.33268 2.12042 2.47601 2.06105 2.62545 2.06105C2.77489 2.06105 2.91821 2.12042 3.02389 2.22609L5.99998 5.20312L8.97701 2.22562C9.08268 2.11995 9.226 2.06059 9.37545 2.06059C9.52489 2.06059 9.66821 2.11995 9.77389 2.22562C9.87956 2.3313 9.93892 2.47462 9.93892 2.62406C9.93892 2.7735 9.87956 2.91683 9.77389 3.0225L6.79685 6L9.77295 8.97703Z" fill="#0B0B0B" fillOpacity="0.5"/>
                        </svg>
                    </div>
                </div>

                <div className="p-[12px]">
                    <GraphComponent title="Graph Title" />
                    <WeatherDashboard />
                    <div className=" dark:border-[#ffffff1a] rounded-[4px] border border-[rgba(0, 0, 0, 0.10)]">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="w-[188px]">
                                        <div className="text-xs font-semibold dark:text-white"> 
                                            <span>Data Type</span>
                                            <input type="text" placeholder="Filter" className="map-side-input mx-[5px] max-w-[115px]" />
                                            <svg viewBox="0 0 24 24">
                                                <path d="M13.2 8.4L1.19995 8.4L7.19995 16.8L13.2 8.4ZM10.8 16.8H22.8L16.8 8.4L10.8 16.8Z"/>
                                            </svg>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="text-xs font-semibold dark:text-white">
                                            <span >Data</span>
                                            <input type="text" placeholder="Filter" className="map-side-input mx-[5px]" />
                                            <svg viewBox="0 0 24 24">
                                                <path d="M13.2 8.4L1.19995 8.4L7.19995 16.8L13.2 8.4ZM10.8 16.8H22.8L16.8 8.4L10.8 16.8Z"/>
                                            </svg>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="text-xs font-semibold dark:text-white">
                                            <span>X Axis Measurement</span>
                                        </div>
                                    </th>
                                    <th className="text-xs font-semibold text-center dark:text-white"><span>Select</span></th>
                                </tr>
                            </thead>
                            <tbody className="text-xs ">
                                {[...Array(10)].map((_, idx) => <tr className="h-[40px]" key={idx}>
                                    <td>Wind {idx + 1}</td>
                                    <td>Wind Speed {idx + 1}</td>
                                    <td>m/s</td>
                                    <td>
                                        <div className="checkbox-red-cont">
                                            <input type="checkbox" className="w-[16px] h-[16px]" />
                                            <span className="checkmark-red"></span>
                                        </div>
                                    </td>
                                </tr>)}
                                
                            </tbody>
                        </table>

                        <div className="flex items-center justify-center h-[40px] table-footer">
                            <div className="flex items-center">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.5 16L9.5 11.97L15.5 8L15.5 16Z" fill="#0B0B0B" fillOpacity="0.5"/>
                                </svg>
                                <span className="nav-option active">1</span>
                                <span className="nav-option">2</span>
                                <span className="nav-option">3</span>
                                <span className="nav-option">4</span>
                                <span className="nav-option">5</span>
                                <span className="nav-option">...</span>

                                <svg style={{transform: 'rotate(180deg)'}} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.5 16L9.5 11.97L15.5 8L15.5 16Z" fill="#0B0B0B" fillOpacity="0.5"/>
                                </svg>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    )
}

export default SelectGraphData;
import MissionPlanner from "./MissionPlanner";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {fetchMissionWayPoints} from "../../store/thunks/fleetThunks";

export const MapWindyContainer = () => {
    const params = useParams()
    const missionId = params.missionId

    const dispatch = useDispatch();
    
    const [missionWayPoints, setMissionWayPoints] = useState(null)

    useEffect(() => {
        const req = async () => {
            const res = await dispatch(fetchMissionWayPoints(missionId));

            if (res?.payload) {
                setMissionWayPoints(res.payload)
            }

        }

        req();
    }, [missionId]);

    return (
        <>
            <MissionPlanner
                missionWayPoints={missionWayPoints}
                missionId={missionId}
            />
        </>
    )
}
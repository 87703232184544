const usersBase = 'users';

export const users = (instance) => ({
    async getUser(payload) {
        const { data } = await instance.get(`${usersBase}/me`);
        return data;
    },

    async updateProfile(payload) {
        const { data } = await instance.put(`${usersBase}/me`, payload);
        return data;
    },

    async updateNotifications(payload) {
        const { data } = await instance.post(`/notifications/me`, payload);
        return data;
    },

    async changePassword(payload) {
        const { data } = await instance.post(`${usersBase}/me/change-password`, payload);
        return data;
    },

    async uploadImage(payload) {
        const { data } = await instance.postForm(`${usersBase}/me`, payload, {method: 'put',  headers: {'Content-Type': 'multipart/form-data'} });
        return data;
    },
});
import { useState, useEffect } from 'react';
import SortIcon from '../../assets/img/icons/sort.svg';
import SortAsc from '../../assets/img/icons/sort-asc.svg';
import SortDesc from '../../assets/img/icons/sort-desc.svg';

import ascIcon from '../../assets/img/icons/asc.svg';
import ascAscIcon from '../../assets/img/icons/asc-asc.svg';
import ascDescIcon from '../../assets/img/icons/asc-desc.svg';

import ArrowLeft from '../../assets/img/icons/arrow-left.svg';
import ArrowRight from '../../assets/img/icons/arrow-right.svg';
import EditIcon from '../../assets/img/icons/edit.svg';
import DeleteIcon from '../../assets/img/icons/delete.svg';

import NewConfigurationModal from '../../Modals/NewConfigurationModal';

const tableData = [
    {
        id: 1,
        name: 'Dashboard Lorem',
        description: 'A Dashboard Lorem ipsum dolor sit amet',
        date_created: '30/01/24',
        last_modified: '30/01/24, 20:50',
    },
    {
        id: 8,
        name: 'Dashboard Ipsum',
        description: 'A Dashboard',
        date_created: '30/01/24',
        last_modified: '30/01/24, 19:51',
    },
    {
        id: 9,
        name: 'Dashboard dolor',
        description: 'A Dashboard consectetur adipiscing elit',
        date_created: '30/01/24',
        last_modified: '30/01/24, 18:52',
    },
    {
        id: 10,
        name: 'Dashboard sit amet',
        description: 'A Dashboard Nulla nec purus feugiat',
        date_created: '30/01/24',
        last_modified: '30/01/24, 17:53',
    },
    {
        id: 2,
        name: 'Dashboard consectetur',
        description: 'A Dashboard vestibulum ligula at',
        date_created: '30/01/24',
        last_modified: '30/01/24, 16:54',
    },
    {
        id: 3,
        name: 'Dashboard adipiscing',
        description: 'A Dashboard malesuada nunc',
        date_created: '30/01/24',
        last_modified: '30/01/24, 15:55',
    },
    {
        id: 4,
        name: 'Dashboard elit',
        description: 'A Dashboard Suspendisse potenti',
        date_created: '30/01/24',
        last_modified: '30/01/24, 14:56',
    },
    {
        id: 5,
        name: 'Dashboard Nulla',
        description: 'A Dashboard Nunc in libero ut ligula aliquet vestibulum',
        date_created: '30/01/24',
        last_modified: '30/01/24, 13:57',
    },
    {
        id: 6,
        name: 'Dashboard nec purus',
        description: 'A Dashboard Maecenas a quam sit amet est tincidunt pharetra.',
        date_created: '30/01/24',
        last_modified: '30/01/24, 12:58',
    },
    {
        id: 7,
        name: 'Dashboard feugiat',
        description: 'A Dashboard sit amet, consectetur adipiscing elit',
        date_created: '30/01/24',
        last_modified: '30/01/24, 11:59',
    },
]

const DashboardHeader = () => {
    return (
        <header className="flex gap-5 justify-between pt-4 pb-4 w-full border-b border-solid border-b-[color:var(--black-25,rgba(11,11,11,0.25))] max-md:flex-wrap max-md:max-w-full">
            <div className="flex gap-2 justify-between max-md:flex-wrap max-md:max-w-full">
                <div className="flex flex-col flex-1 justify-center text-neutral-950 pr-2 dark:text-white">
                    <div className="text-3xl font-bold tracking-wider whitespace-nowrap">Manage Configurations</div>
                </div>
            </div>
        </header>
    );
};

const formatSortValueToIcon = (ascValue) => {
    if (ascValue > 0) return SortAsc;
    if (ascValue < 0) return SortDesc;
    return SortIcon;
}

const formatAscValueToIcon = (ascValue) => {
    if (ascValue > 0) return ascAscIcon;
    if (ascValue < 0) return ascDescIcon;
    return ascIcon;
}

const DataTable = ({ tdata }) => {
    const [filteredData, setFilteredData] = useState(tdata);
    const [filters, setFilters] = useState([
        { title: 'Name', sortable: true, sortValue: 0, filterValue: '' }, //sortValue: 0 || 1 || -1
        { title: 'Description', sortable: true, sortValue: 0, filterValue: '' },
        { title: 'Date Created', sortable: true, sortValue: 0, filterValue: '', asc: true, ascValue: 0 }, //ascValue: 0 || 1 || -1
        { title: 'Last Modified', sortable: true, sortValue: 0, filterValue: '', asc: true, ascValue: 0  },
    ]);

    const [editModalState, setModalState] = useState({
        show: false,
        dashItem: null,
    });

    const handleascChange = (index) => {
        setFilters((oldFilters) => {
            const newFilters = [...oldFilters];
            const target = newFilters[index].ascValue;
            if(target === 0) newFilters[index].ascValue = 1;
            else if(target === 1) newFilters[index].ascValue = -1;
            else if(target === -1) newFilters[index].ascValue = 0;
            return newFilters
        });
    }

    const handleSortChange = (index) => {
        const newFilters = [...filters];
        const target = newFilters[index].sortValue;
        if(target === 0) newFilters[index].sortValue = 1;
        else if(target === 1) newFilters[index].sortValue = -1;
        else if(target === -1) newFilters[index].sortValue = 0;
        setFilters(newFilters);
    }

    const handleFilterInput = (e, index) => {
        const { name, value } = e.target;
        setFilters((oldFilters) => {
            const newFilters = [...oldFilters];
            newFilters[index].filterValue = value;
            return newFilters;
        });
    }

    useEffect(() => {
        // TODO: Implement sorting and filtering
        const newFilteredData = tdata.filter((row) => {
            return filters.every((filter, index) => {
                if (filter.filterValue === '') return true;
                return row[filter.title.toLowerCase()].toLowerCase().includes(filter.filterValue.toLowerCase());
            });
        });

        newFilteredData.sort((a, b) => {
            return filters.reduce((acc, filter, index) => {
                if (filter.sortValue === 0) return acc;
                const valueA = a[filter.title.toLowerCase()];
                const valueB = b[filter.title.toLowerCase()];
                if (valueA < valueB) return acc + filter.sortValue;
                if (valueA > valueB) return acc - filter.sortValue;
                return acc;
            }, 0);
        });

        newFilteredData.sort((a, b) => {
            return filters.reduce((acc, filter, index) => {
                if (!filter.asc) return acc;
                if (filter.ascValue === 0) return acc;
                const valueA = a[filter.title.toLowerCase()];
                const valueB = b[filter.title.toLowerCase()];
                if (valueA < valueB) return acc + filter.ascValue;
                if (valueA > valueB) return acc - filter.ascValue;
                return acc;
            }, 0);
        });

        setFilteredData(newFilteredData);
    }, [filters]);

    const handleEdit = (row) => {
        console.log('Edit:', row);
        setModalState({
            show: true,
            dashItem: row,
        });
    }

    const handleNewConfig = (data) => {
        console.log('New Config:', data);
        setFilteredData((oldData) => {
            const newData = [...oldData];
            const index = newData.findIndex((row) => row.id === data.id);
            if (index > -1) {
                newData[index] = data;
            } else {
                newData.push(data);
            }
            return newData;
        })
        setModalState({
            show: false,
            dashItem: null,
        });
    }

    return (
        <div className="overflow-x-auto rounded border border-solid border-neutral-950 border-opacity-30">
            <table className="min-w-full text-xs">
                <thead className='bg-white dark:bg-[#0B0B0B]'>
                    <tr>
                        {
                            filters.map((filter, index) => {
                                return <th key={index} className="text-left py-1 px-2">
                                    <div className="flex gap-2 items-center sortable-column filter-column">
                                        <span className="sortable-column__text dark:text-white">{filter.title}</span>
                                        <input
                                            onInput={(e) => handleFilterInput(e, index)}
                                            name={filter.title}
                                            value={filter.filterValue} 
                                            type="text" 
                                            className="p-1 bg-white dark:bg-[#0B0B0B] rounded border border-solid border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"/>
                                        {
                                            filter.asc && 
                                            <span className="cursor-pointer sortable-column__icon asc-filter" onClick={() => handleascChange(index)}>
                                                <img loading="lazy" src={formatAscValueToIcon(filter.ascValue)} alt="Sort Icon" className="size-6" />
                                            </span>
                                        }
                                        {
                                            filter.sortable && 
                                            <span className="cursor-pointer sortable-column__icon sort-filter" onClick={() => handleSortChange(index)}>
                                                <img loading="lazy" src={formatSortValueToIcon(filter.sortValue)} alt="Sort Icon" className="size-6" />
                                            </span>
                                        }
                                    </div>
                                </th>
                            })
                        }
                        <th className="text-left py-1 px-2 dark:text-white">Edit</th>
                        <th className="text-left py-1 px-2 dark:text-white">Remove</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredData.map((row, index) => {
                            return <tr key={index} className={`${index % 2 ? 'bg-gray-100s dark:bg-[#1B1B1B]' : 'bg-[#fbfbfb] dark:bg-[#151515]'} border-t border-solid border-neutral-950 border-opacity-10 text-left dark:text-white dark:text-opacity-50`}>
                                    <td className="py-2 px-2">{row.name}</td>
                                    <td className="py-2 px-2">{row.description}</td>
                                    <td className="py-2 px-2">{row.date_created}</td>
                                    <td className="py-2 px-2">{row.last_modified}</td>
                                    <td className="py-2 px-2 dark-bright">
                                        <button className='block size-6' onClick={(e) => handleEdit(row)}><img src={EditIcon} className='block size-6'/></button>
                                    </td>
                                    <td className="py-2 px-2 dark-bright">
                                        <button className='block size-6'><img src={DeleteIcon} className='block size-6'/></button>
                                    </td>
                                </tr>
                            }
                    )}
                </tbody>
            </table>
            <div className="bg-white dark:bg-[#0B0B0B] table-pagination-footer p-3 border-t border-solid bg-zinc-50 border-neutral-950 border-opacity-10">
                <ul className="table-pagination flex justify-center items-center gap-2 text-xs font-semibold dark:text-white dark:text-opacity-50">
                    <li className="table-pagination__item">
                        <button className="block table-pagination__button">
                            <img src={ArrowLeft}/>
                        </button>
                    </li>
                    <li className="table-pagination__item active">
                        <button className="block table-pagination__button">1</button>
                    </li>
                    <li className="table-pagination__item">
                        <button className="block table-pagination__button">2</button>
                    </li>
                    <li className="table-pagination__item">
                        <button className="block table-pagination__button">3</button>
                    </li>
                    <li className="table-pagination__item">
                        <button className="block table-pagination__button">4</button>
                    </li>
                    <li className="table-pagination__item">
                        <button className="block table-pagination__button">5</button>
                    </li>
                    <li className="table-pagination__item">
                        <button className="block table-pagination__button">...</button>
                    </li>
                    <li className="table-pagination__item">
                        <button className="block table-pagination__button">
                            <img src={ArrowRight}/>
                        </button>
                    </li>
                </ul>
            </div>

            { editModalState.show && <NewConfigurationModal dashItem={editModalState.dashItem} onConfirm={handleNewConfig} onClose={() => setModalState({ show: false, dashItem: null })}/> }
        </div>
    );

}
  

export const ManageConfigurations = () => {
    return (
        <>
            <DashboardHeader />
            <div className="dashboard-layout__content pt-5">
                <DataTable tdata={tableData}/>
            </div>
        </>
    )
}   
import {ReactComponent as SailIcon} from '../../assets/img/icons/sail.svg'
import {ReactComponent as ClockIcon} from '../../assets/img/icons/clock.svg'
import CheckGreenIcon from '../../assets/img/icons/check-green.svg'
import DraftIcon from '../../assets/img/icons/draft.svg'

export const statusTypes = {
    in_progress: 'In Progress',
    complete: 'Complete',
    draft: 'Draft',
    scheduled: 'Scheduled',

}

export const statusButtons = {
    in_progress: <span className="pill pill-in-progress whitespace-nowrap flex mx-auto items-center gap-[4px] px-[8px] py-[4px] rounded-[360px]">
        <div className="flex">
            <SailIcon className='icon-blue'/>
        </div>
        In Progress
    </span>,
    complete: <span className="pill pill-complete whitespace-nowrap flex mx-auto items-center gap-[4px] px-[8px] py-[4px] rounded-[360px]">
        <div className="flex">
            <img src={CheckGreenIcon}/>
        </div>
        Complete
    </span>,
    draft: <span className="pill pill-draft whitespace-nowrap flex mx-auto items-center gap-[4px] px-[8px] py-[4px] h-[32px] rounded-[360px]">
        <div className="flex">
            <img src={DraftIcon} />
        </div>
        Draft
    </span>,
    blueprint: <span className="pill pill-draft whitespace-nowrap flex mx-auto items-center gap-[4px] px-[8px] py-[4px] h-[32px] rounded-[360px]">
        <div className="flex">
            <img src={DraftIcon} />
        </div>
        Blueprint
    </span>,
    scheduled: <span className="pill pill-scheduled whitespace-nowrap mx-auto flex items-center gap-[4px] px-[8px] py-[4px] h-[32px] rounded-[360px]">
        <div className="flex">
            <ClockIcon className='icon-purple'/>
        </div>
        Scheduled
    </span>,
}

export default {
    statusTypes,
    statusButtons
}
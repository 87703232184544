import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

import { BarChart } from "../../components/widgets/BarChart";
import { LineChart } from "../../components/widgets/LineChart";
import { PieChart } from "../../components/widgets/PieChart";

import AddWidgetIcon from "../../assets/img/icons/add-widget.svg";
import EditLayoutIcon from "../../assets/img/icons/edit-layout.svg";
import NewConfigIcon from "../../assets/img/icons/new-config.svg";
import SaveIcon from "../../assets/img/icons/save.svg";
import LoadIcon from "../../assets/img/icons/load.svg";
import ManageConfigsIcon from "../../assets/img/icons/manage-configs.svg";
import ArrowDownIcon from "../../assets/img/icons/arrow-down.svg";
import ShipIcon from "../../assets/img/icons/ship.svg";
import WaypointIcon from "../../assets/img/icons/waypoint.svg";
import WidgetPoster from "../../assets/img/widget-poster.png";
import WidgetPoster2 from "../../assets/img/widget-poster-2.png";
import WindDirection from "../../assets/img/wind-direction.svg";

import AddWidgetModal from "../../Modals/AddWidgetModal";
import LoadConfigModal from "../../Modals/LoadConfigModal";
import SelectGraphData from "../../Modals/SelectGraphDataModal";
import NewConfigurationModal from "../../Modals/NewConfigurationModal";
import DashboardWidgetMap from "../../components/DashboardWidgetMap";
import NamedFilter from "../../components/NamedFilter";
import { useSelector } from "react-redux";
import { getUser } from "../../store/slices/applicationSlice";
const Modals = {
    addWidget: AddWidgetModal,
    loadConfig: LoadConfigModal,
    selectGraph: SelectGraphData,
    newConfig: NewConfigurationModal
}

const ActionButton = ({ imgSrc, altText, actionText, transparent, path, clickAction }) => (
    path &&
    <NavLink to={path} className={`cursor-pointer flex gap-1 justify-between p-1 rounded border border-solid border-[color:#ae1b1d] ${transparent ? 'text-red-600' : 'bg-red-600'}`}>
        <img loading="lazy" src={imgSrc} alt={altText} className="w-6 aspect-square" />
        <div className="grow my-auto">{actionText}</div>
    </NavLink> ||
    <button className={`cursor-pointer flex gap-1 justify-between p-1 rounded border border-solid border-[color:#ae1b1d] ${transparent ? 'text-red-600' : 'bg-red-600'}`} onClick={clickAction}>
        <img loading="lazy" src={imgSrc} alt={altText} className="w-6 aspect-square" />
        <div className="grow my-auto">{actionText}</div>
    </button>
);

export const Dashboard = () => {
    const [activeModal, setActiveModal] = useState(null);
    const user = useSelector(getUser)
    console.log(user)
    const navigate = useNavigate()
    useEffect(() => {
        if(user?.role === 'user') {
            navigate('/data-explorer', {
                replace: true
            })
        }
    }, [user])
    const CurrentModal = Modals[activeModal];

    const handleClose = () => {
        setActiveModal(null);
    }

    const openModal = (modal) => {
        setActiveModal(modal);
    }

    const actionButtons = [
        { imgSrc: AddWidgetIcon, altText: "Add Widget Icon", actionText: "Add Widget", clickAction: () => setActiveModal("addWidget") },
        { imgSrc: EditLayoutIcon, altText: "Edit Layout Icon", actionText: "Edit Layout", clickAction: () => setActiveModal("addWidget") },
        { imgSrc: NewConfigIcon, altText: "New Config Icon", actionText: "New Config", clickAction: () => setActiveModal("newConfig") },
        { imgSrc: SaveIcon, altText: "Save Icon", actionText: "Save", transparent: true, borderColorClass: "text-red-600" },
        { imgSrc: LoadIcon, altText: "Load Icon", actionText: "Load", transparent: true, borderColorClass: "text-red-600", clickAction: () => setActiveModal("loadConfig") },
        { imgSrc: ManageConfigsIcon, altText: "Manage Configs Icon", actionText: "Manage Configs", transparent: true, borderColorClass: "text-red-600", path: "manage-configurations" },
    ];

    return (
        <>
            <header className="flex gap-5 justify-between pb-2 w-full border-b border-solid border-b-[color:var(--black-25,rgba(11,11,11,0.25))] max-md:flex-wrap max-md:max-w-full">
                <div className="flex gap-2 justify-between max-md:flex-wrap max-md:max-w-full">
                    <div className="flex flex-col flex-1 justify-center text-neutral-950 pr-2 border-r-[color:#dedede] dark:text-white">
                        <div className="text-base font-semibold tracking-wider">Dashboard Name</div>
                        <div className="text-3xl font-bold tracking-wider whitespace-nowrap"> Mission Summary </div>
                    </div>
                    {/* <div className="header-datetime-picker">
                        <DatePicker 
                            className="text-xs tracking-wide whitespace-nowrap bg-white rounded border border-solid border-[color:var(--black-25,rgba(11,11,11,0.25))] text-neutral-950 text-opacity-50 dark:bg-[#0B0B0B]"
                            placeholderText="04/02/24, 10:05 - 13:00"
                            selectsMultiple
                            showIcon
                            toggleCalendarOnIconClick
                            icon={<img loading="lazy" src={ArrowDownIcon} alt="Clock Icon" className="w-6 aspect-square right-0" /> }
                            showTimeSelect
                        />
                    </div> */}
                </div>
                <div className="flex gap-2 py-3.5 text-xs font-semibold tracking-wide text-white whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                    {actionButtons.map((button, index) => (
                        <ActionButton key={index} {...button} />
                    ))}
                </div>
            </header>
            <div className="mt-6">
            <NamedFilter onChange={() => { }} />
            </div>

            <div className="dashboard-layout__content">
                <div className="widgets-wrapper flex flex-wrap gap-5 pt-4">
                    <div className="widget-card rounded w-1/2-gap-5 border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white">
                        <LineChart openModal={openModal} />
                    </div>
                    <div className="widget-card rounded w-1/2-gap-5 border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white">
                        <LineChart openModal={openModal} />
                    </div>

                    <div className="widget-card rounded w-1/6-gap-5 border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white">
                        <div className="widget-inner h-56 flex flex-column">
                            <img src={WidgetPoster} className="flex-1 object-cover" />
                        </div>
                    </div>
                    <div className="widget-card rounded w-1/6-gap-5 border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white">
                        <div className="widget-inner h-56 flex flex-column">
                            <DashboardWidgetMap />
                        </div>
                    </div>
                    <div className="widget-card rounded w-1/3-gap-5 border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white">
                        <BarChart />
                    </div>
                    <div className="widget-card rounded w-1/3-gap-5 border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white">
                        <BarChart title="Heading From Wind (Average)" />
                    </div>

                    <div className="widget-card rounded w-1/6-gap-5 border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white">
                        <div className="widget-inner h-56 overflow-auto rounded flex flex-col self-stretch text-xs font-semibold tracking-wide text-neutral-950 text-opacity-50 border border-solid border-neutral-950 border-opacity-10 dark:text-white dark:text-opacity-50 dark:border-white dark:border-opacity-50">
                            <div className="audio-row bg-[#eff2f4] px-5 py-1 w-full dark:bg-[#151515] cursor-pointer">
                                Audio Clip 1
                            </div>
                            <div className="cursor-pointer audio-row bg-[#eff2f4] px-5 py-1 w-full border-t border-solid border-neutral-950 border-opacity-10 dark:bg-[#151515] dark:border-white dark:border-opacity-50">
                                Audio Clip 2
                            </div>
                            <div className="cursor-pointer audio-row bg-[#eff2f4] px-5 py-1 w-full border-t border-solid border-neutral-950 border-opacity-10 dark:bg-[#151515] dark:border-white dark:border-opacity-50">
                                Audio Clip 3
                            </div>
                            <div className="cursor-pointer audio-row bg-[#eff2f4] px-5 py-1 w-full border-t border-solid border-neutral-950 border-opacity-10 dark:bg-[#151515] dark:border-white dark:border-opacity-50">
                                Audio Clip 4
                            </div>
                            <div className="cursor-pointer audio-row bg-[#eff2f4] px-5 py-1 w-full border-t border-solid border-neutral-950 border-opacity-10 dark:bg-[#151515] dark:border-white dark:border-opacity-50">
                                Audio Clip 5
                            </div>
                            <div className="cursor-pointer audio-row bg-[#eff2f4] px-5 py-1 w-full border-t border-solid border-neutral-950 border-opacity-10 dark:bg-[#151515] dark:border-white dark:border-opacity-50">
                                Audio Clip 6
                            </div>
                            <div className="cursor-pointer audio-row bg-[#eff2f4] px-5 py-1 w-full border-t border-solid border-neutral-950 border-opacity-10 dark:bg-[#151515] dark:border-white dark:border-opacity-50">
                                Audio Clip 7
                            </div>
                            <div className="cursor-pointer audio-row bg-[#eff2f4] px-5 py-1 w-full border-t border-solid border-neutral-950 border-opacity-10 dark:bg-[#151515] dark:border-white dark:border-opacity-50">
                                Audio Clip 8
                            </div>
                            <div className="cursor-pointer audio-row bg-[#eff2f4] px-5 py-1 w-full border-t border-solid border-neutral-950 border-opacity-10 dark:bg-[#151515] dark:border-white dark:border-opacity-50">
                                Audio Clip 9
                            </div>
                        </div>
                    </div>

                    <div className="widget-card rounded w-1/6-gap-5 border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white">
                        <PieChart />
                    </div>

                    <div className="widget-card rounded w-1/6-gap-5 border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white">
                        <div className="widget-inner h-56 flex flex-col">
                            <div className="text-xs font-semibold tracking-wide text-neutral-950 max-md:max-w-full dark:text-white">
                                Wind Direction
                            </div>
                            {/* <img src={WindDirection} className="flex-1 object-contain max-h-[191px]"/> */}
                            <svg width="100%" height="191" viewBox="0 0 220 191" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex-1 object-contain max-h-[191px]">
                                <path d="M42.5765 90L39.6365 104H37.2165L34.4965 93.22L31.7765 104H29.3565L26.4165 90H29.1165L30.7765 99.92L33.2765 90H35.7165L38.2165 99.88L39.8765 90H42.5765Z" fill="#2086E4" />
                                <path d="M118.486 11.5V25.5H116.126L110.026 15.68V25.5H107.506V11.5H110.006L115.966 21.16V11.5H118.486Z" fill="#E42320" />
                                <g filter="url(#filter0_i_239_5847)">
                                    <g clipPath="url(#clip0_239_5847)">
                                        <rect x="47.5" y="30.5" width="131" height="130" rx="65" fill="#EFF2F4" className="dark:fill-[#151515]" />
                                        <path d="M47.5 95.5H67.5" stroke="#2086E4" strokeWidth="2" strokeLinecap="round" />
                                        <path d="M67.5 49.5L73.1569 55.1569" stroke="#2086E4" strokeLinecap="round" />
                                        <path d="M158.5 140.5L152.843 134.843" stroke="#2086E4" strokeLinecap="round" />
                                        <path d="M67.8136 141.186L73.4705 135.529" stroke="#2086E4" strokeLinecap="round" />
                                        <path d="M159 50L153.343 55.6569" stroke="#2086E4" strokeLinecap="round" />
                                        <path d="M113.5 30.5V50.5" stroke="#2086E4" strokeWidth="2" strokeLinecap="round" />
                                        <path d="M113.5 140.5V160.5" stroke="#2086E4" strokeWidth="2" strokeLinecap="round" />
                                        <path d="M158.5 95.5H178.5" stroke="#2086E4" strokeWidth="2" strokeLinecap="round" />
                                        <g clipPath="url(#clip1_239_5847)">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M112.483 38.6092C112.68 38.2055 112.917 37.992 113.161 38.0002C113.399 38.0074 113.631 38.2281 113.823 38.6307C114.014 39.0334 114.156 39.5973 114.228 40.2419L120.124 93.4616C120.245 94.0147 120.304 94.6589 120.292 95.3084C120.294 95.4331 120.294 95.5577 120.291 95.6816C120.279 96.2042 120.223 96.7139 120.124 97.1628L114.227 150.383C113.895 153.378 112.398 153.378 112.066 150.383L106.169 97.1666C106.071 96.7164 106.014 96.2057 106.002 95.6816C106 95.5607 105.999 95.439 106.002 95.3173C105.989 94.6661 106.048 94.0199 106.169 93.4654L112.067 40.2419C112.14 39.5854 112.286 39.0129 112.483 38.6092ZM111.171 95.6816H108.378L113.147 138.718L118.031 95.6816H115.124H111.171Z" fill="#E42320" />
                                        </g>
                                    </g>
                                    <rect x="48" y="31" width="130" height="129" rx="64.5" stroke="#2086E4" />
                                </g>
                                <path d="M112.961 181.7C111.961 181.7 111.041 181.493 110.201 181.08C109.374 180.667 108.701 180.08 108.181 179.32L109.681 177.88C110.107 178.4 110.627 178.827 111.241 179.16C111.867 179.493 112.467 179.66 113.041 179.66C113.721 179.66 114.261 179.5 114.661 179.18C115.074 178.86 115.281 178.44 115.281 177.92C115.281 177.547 115.214 177.24 115.081 177C114.947 176.747 114.701 176.5 114.341 176.26C113.981 176.007 113.427 175.693 112.681 175.32C111.694 174.84 110.914 174.387 110.341 173.96C109.767 173.533 109.347 173.08 109.081 172.6C108.814 172.12 108.681 171.567 108.681 170.94C108.681 170.22 108.867 169.587 109.241 169.04C109.627 168.48 110.161 168.053 110.841 167.76C111.521 167.453 112.307 167.3 113.201 167.3C114.174 167.3 115.041 167.493 115.801 167.88C116.574 168.267 117.141 168.793 117.501 169.46L115.881 170.66C115.561 170.247 115.174 169.927 114.721 169.7C114.281 169.46 113.807 169.34 113.301 169.34C112.647 169.34 112.134 169.48 111.761 169.76C111.401 170.027 111.221 170.407 111.221 170.9C111.221 171.207 111.301 171.473 111.461 171.7C111.634 171.913 111.934 172.153 112.361 172.42C112.801 172.687 113.467 173.033 114.361 173.46C115.627 174.06 116.514 174.687 117.021 175.34C117.541 175.98 117.801 176.787 117.801 177.76C117.801 178.547 117.601 179.24 117.201 179.84C116.801 180.427 116.234 180.887 115.501 181.22C114.767 181.54 113.921 181.7 112.961 181.7Z" fill="#2086E4" />
                                <path d="M184.306 90H193.286V92.04H186.826V95.62H192.606V97.66H186.826V101.96H193.286V104H184.306V90Z" fill="#2086E4" />
                                <defs>
                                    <filter id="filter0_i_239_5847" x="47.5" y="30.5" width="131" height="130" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_innerShadow_239_5847" />
                                        <feOffset />
                                        <feGaussianBlur stdDeviation="2" />
                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_239_5847" />
                                    </filter>
                                    <clipPath id="clip0_239_5847">
                                        <rect x="47.5" y="30.5" width="131" height="130" rx="65" fill="white" />
                                    </clipPath>
                                    <clipPath id="clip1_239_5847">
                                        <rect width="45" height="115" fill="white" transform="translate(90.5 38)" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                    </div>

                    <div className="widget-card rounded w-1/6-gap-5 border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white">
                        <div className="widget-inner h-56 flex flex-col">
                            <div className="text-xs font-semibold tracking-wide text-neutral-950 max-md:max-w-full dark:text-white">
                                Distance Traveled
                            </div>
                            <div className="h-44 w-full mt-1 flex flex-col items-center justify-center">
                                <p className="text-6xl font-black tracking-[2.56px] font-semibold text-blue-500">100</p>
                                <div className="shrink-0 mt-2 h-px bg-blue-500 border border-blue-500 border-solid w-[117px]" />
                                <div className="flex gap-5 justify-between mt-2 max-w-full text-xl tracking-wider whitespace-nowrap w-[117px]">
                                    <img src={ShipIcon} className="flex-1 size-6" />
                                    <p className="text-blue-500 flex-1">Km</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="widget-card rounded w-1/6-gap-5 border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white">
                        <div className="widget-inner h-56 flex flex-col">
                            <div className="text-xs font-semibold tracking-wide text-neutral-950 max-md:max-w-full dark:text-white">
                                Total Waypoints
                            </div>
                            <div className="h-44 w-full mt-1 flex flex-col items-center justify-center">
                                <p className="text-6xl font-black tracking-[2.56px] font-semibold text-blue-500">6</p>
                                <div className="shrink-0 mt-2 h-px bg-blue-500 border border-blue-500 border-solid w-[117px]" />
                                <div className="flex gap-5 justify-between mt-2 max-w-full text-xl tracking-wider whitespace-nowrap w-[117px]">
                                    <img src={WaypointIcon} alt="Distance unit indicator" className="flex-1 size-6" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="widget-card rounded w-1/6-gap-5 border border-solid shadow-sm bg-zinc-50 border-red-600 border-opacity-30 bg-zinc-50 p-3 dark:bg-[#1B1B1B] dark:text-white">
                        <PieChart
                            title="Status Distribution"
                            data={[41.7, 16.7, 16.7, 25]}
                            labels={['41.7%', '16.7%', '16.7%', '25%']}
                            backgroundColor={['#1c55c3', '#149d32', '#11acb6', '#de1414']}
                        // Autonomous Sailing
                        // (41.7%)
                        // Battery Saving
                        // (16.7%)
                        // Error (16.7%)
                        // Station-Keeping
                        // (25%)
                        />
                    </div>

                </div>
            </div>

            {

                CurrentModal && <CurrentModal close={handleClose} onConfirm={handleClose} />
            }
            {/* <AddWidgetModal/> */}
        </>
    )
}   
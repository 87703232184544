import { Outlet } from "react-router-dom"
import { MapSideMenu } from "../components/MapSideMenu"

export default () => {
    return (
        <div className="min-h-screen dashboard-wrapper dark:bg-[#1B1B1B]">
            <div className="fixed top-[10px] left-[10px] z-10">
                <MapSideMenu />
            </div>

            <div className="dashboard-layout flex flex-col flex-1">
                <Outlet/>
            </div>
        </div>
    )
}
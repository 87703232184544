import { useState } from "react";
import PanelButton from "../components/base/PanelButton";
import { ReactComponent as SaveIcon } from "../assets/img/icons/save.svg";
import ReactDatePicker from "react-datepicker";
import { ReactComponent as ClockIcon } from "../assets/img/icons/clock.svg";
import { ReactComponent as CalendarIcon } from "../assets/img/icons/calendar.svg";
import InputBox from "../components/base/InputBox";
import {useDispatch} from "react-redux";
import {fetchChangePasswordThunk} from "../store/thunks/applicationThunks";

const InputField = ({ label, placeholder, value, onChange }) => (
    <div className="flex flex-col mt-2 dark:text-white">
        <div>{label}</div>
        <input
            className="px-2 pt-1 pb-7 mt-2 whitespace-nowrap bg-white rounded border border-solid border-neutral-950 border-opacity-30 text-notmal text-neutral-950 dark:bg-[#0B0B0B] dark:text-white"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        />
    </div>
);

const ConfigHeader = ({onClose, type}) => (
    <div className="flex gap-5 justify-between px-2 py-1 w-full bg-gray-100 border-b border-solid border-neutral-950 border-opacity-10 text-neutral-950 text-opacity-50 dark:text-white dark:bg-[#151515]">
        <div>Change Password</div>
        <button onClick={onClose}>
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ddcb33ebb0cb0d777eaebba2ca6701637f967354c4f2b41114a225bd26c5a850?apiKey=9106ba04c02e4dceae488e75e2ac4862&"
                className="shrink-0 my-auto w-3 aspect-square close-icon"
            />
        </button>
    </div>
);

const ConfirmButton = ({ onClick }) => (
    <button
        className="justify-center self-end px-1 py-2 mt-2 text-white whitespace-nowrap bg-red-600 rounded border border-solid border-neutral-950 border-opacity-30"
        onClick={onClick}
    >
        Confirm
    </button>
);

const ChangePasswordModal = ({ dashItem, onConfirm, close, type }) => {
    const [name, setName] = useState(dashItem?.name || "");
    const [description, setDescription] = useState(dashItem?.description || "");
    const dispatch = useDispatch()

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleConfirm = () => {
        // Handle form submission here
        console.log("Name:", name);
        console.log("Description:", description);
        onConfirm({ ...dashItem, name, description })
    };
    const [startDate, setStartDate] = useState(null);
    const onChange= (date) => setStartDate(date)

    const [formData, setFormData] = useState({
        password: '',
        newPassword: '',
        newPasswordSubmit: ''
    })

    const [errors, setErrors] = useState({})
    const [isDisabled, setIsDisabled] = useState(false)

    const handleChange = (key, value) => {
        setFormData(prevState => ({...prevState, [key]: value}))
        setErrors(prevState => ({...prevState, [key]: false}))
    }

    
    const submit = async () => {
        let er = false
        if (formData.password.length < 6) {
            setErrors(prevState => ({...prevState, 'password': true}))
            er = true
        }
        if (formData.newPassword.length < 6) {
            setErrors(prevState => ({...prevState, 'newPassword': true}))
            er = true
        }
        if (formData.newPassword !== formData.newPasswordSubmit) {
            setErrors(prevState => ({...prevState, 'newPasswordSubmit': true}))
            er = true
        }

        if (Object.values(errors).some(value => value === true) || er) {
            return
        }

        setIsDisabled(true)
        const res = await dispatch(fetchChangePasswordThunk({password: formData.password, newPassword: formData.newPassword}))
        setIsDisabled(false)

        if (res?.payload?.success) {
            close && close()
        }

    }

  return (
    <div style={{    
            position: 'fixed',
            width: type === 'save' ? 262 : 372,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }} 
        className="flex flex-col text-xs tracking-wide rounded bg-zinc-50 max-w-[336px] border border-solid border-neutral-950 border-opacity-30 dark:border-[#ffffff1a] dark:bg-[#1b1b1b]">
        <ConfigHeader type={type} onClose={close}/>
        <div className="flex flex-col justify-end px-3 pb-3 w-full font-semibold text-neutral-950">
            
        <div className="mt-[12px] mb-[8px]">Old Password</div>
        <InputBox 
            type='password'
            placeholder='Old Password'
            value={formData.password}
            wrapperClassName={`${errors['password'] ? 'input-error' : '' } `}
            disabled={isDisabled}
            onChange={(e) => handleChange('password', e.target.value)}
        />
        <div className="mt-[12px] mb-[8px]">New Password</div>
        <InputBox 
            type='password'
            placeholder='New Password'
            wrapperClassName={`${errors['newPassword'] ? 'input-error' : '' } `}
            value={formData.newPassword}
            disabled={isDisabled}
            onChange={(e) => handleChange('newPassword', e.target.value)}
        />
        <div className="mt-[12px] mb-[8px] dark:">Confirm New Password</div>
        <InputBox 
            type='password'
            placeholder='Confirm New Password'
            wrapperClassName={`${errors['newPasswordSubmit'] ? 'input-error' : '' } `}
            value={formData.newPasswordSubmit}
            disabled={isDisabled}
            onChange={(e) => handleChange('newPasswordSubmit', e.target.value)}
        />
            <PanelButton
                className='w-fit ml-auto mt-[12px]'
                onClick={submit}
                disabled={isDisabled}
            >
                Change Password
            </PanelButton>
        </div>
    </div>
  );
};

export default ChangePasswordModal;
import { createSlice } from '@reduxjs/toolkit'
import { fetchUserThunk, logoutThunk } from '../thunks/applicationThunks';

const initialState = {
    authorized: null,
    user: null,
    token: null,
}

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setAuthorized: (state, action) => {
            state.authorized = action.payload;
        },

        setUser: (state, action) => {
            state.user = action.payload;
        },

        setToken: (state, action) => {
            state.token = action.payload;
        },
    },

    extraReducers(builder) {
        builder.addCase(fetchUserThunk.fulfilled, (state, action) => {
            state.user = action.payload;
            state.authorized = true;
        });
        
        builder.addCase(fetchUserThunk.pending, (state, action) => {
            state.authorized = null;
        });

        builder.addCase(fetchUserThunk.rejected, (state, action) => {
            localStorage.removeItem('authToken');
            state.authorized = false;
            state.token = null;
        });

        builder.addCase(logoutThunk.fulfilled, (state, action) => {
            state.authorized = false;
            state.user = null;
            localStorage.removeItem('authToken');
        });
    }
})

export const { 
    setUser, setAuthorized, setToken,
} = applicationSlice.actions;

export const getUser = (state) => state.application.user;
export const getAuthorized = (state) => state.application.authorized;
export const getToken = (state) => state.application.token;

export default applicationSlice.reducer
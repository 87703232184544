import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";

import SailIcon from '../../assets/img/icons/sail.svg'
import CheckGreenIcon from '../../assets/img/icons/check-green.svg'
import DraftIcon from '../../assets/img/icons/draft.svg'
import ClockIcon from '../../assets/img/icons/clock.svg'

import SortIcon from '../../assets/img/icons/sort.svg';
import SortAsc from '../../assets/img/icons/sort-asc.svg';
import SortDesc from '../../assets/img/icons/sort-desc.svg';

import ascIcon from '../../assets/img/icons/asc.svg';
import ascAscIcon from '../../assets/img/icons/asc-asc.svg';
import ascDescIcon from '../../assets/img/icons/asc-desc.svg';

import ArrowLeft from '../../assets/img/icons/arrow-left.svg';
import ArrowRight from '../../assets/img/icons/arrow-right.svg';

import EditIcon from '../../assets/img/icons/edit.svg';
import DeleteIcon from '../../assets/img/icons/delete.svg';

import { statusButtons } from '../../components/parts/StatusButtons';
import { CreateNewMissionModal } from "../../Modals/CreateNewMissionModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllMissions } from "../../store/slices/missionSlice";
import { fetchAllMission, fetchDeleteMission } from "../../store/thunks/fleetThunks";
import ConfirmationModal from '../../Modals/ConfirmationModal';
import api from '../../api';

const tableFilters = [
    { title: 'Id', id: 'id', filterable: true, sortable: true, sortValue: 0, filterValue: '' }, //sortValue: 0 || 1 || -1
    { title: 'Vessel Name', id: 'vesselId', filterable: true, sortable: true, sortValue: 0, filterValue: '' },
    { title: 'Mission Description', id: 'description', filterable: true, sortable: true, sortValue: 0, filterValue: '' },

    // { title: 'Mission Start', id: 'start', filterable: true, sortable: true, sortValue: 0, filterValue: '', asc: true, ascValue: 0, fType: 'date' }, //ascValue: 0 || 1 || -1
    // { title: 'Mission End', id: 'end', filterable: true, sortable: true, sortValue: 0, filterValue: '', asc: true, ascValue: 0, fType: 'date' },
    { title: 'Mission Start/End', id: 'date', filterable: true, sortable: true, sortValue: 0, filterValue: { start: "", end: "" }, asc: true, ascValue: 0, fType: 'date' },
    { title: 'Status', id: 'status', filterable: false, sortable: true, sortValue: 0, filterValue: '', },
]
const tdata1 = [
    {
        uuID: '1234',
        title: "Vassel 1",
        description: "A short mission 1",
        start: "30/01/24, 9:00",
        end: null,
        status: 'in_progress'
    },
    {
        uuID: '2345',
        title: "Vassel 2",
        description: "A short mission 2",
        start: "30/01/24, 11:45",
        end: null,
        status: 'in_progress'
    },
    {
        uuID: '3456',
        title: "Vassel 3",
        description: "A short mission 3",
        start: "30/01/24, 12:06",
        end: null,
        status: 'in_progress'
    },
    {
        uuID: '4567',
        title: "Vassel 4",
        description: "A short mission 4",
        start: "30/01/24, 13:20",
        end: "30/01/24, 16:23",
        status: "complete"
    },
    {
        uuID: '5678',
        title: "Vassel 5",
        description: "A short mission 5",
        start: "30/01/24, 13:25",
        end: "30/01/24, 16:23",
        status: "complete"
    },
    {
        uuID: '6789',
        title: "Vassel 6",
        description: "A short mission 6",
        start: "30/01/24, 13:28",
        end: "30/01/24, 16:50",
        status: "draft"
    },
    {
        uuID: '7890',
        title: "Vassel 7",
        description: "A short mission 7",
        start: "30/01/24, 14:32",
        end: "30/01/24, 17:17",
        status: "draft"
    },
    {
        uuID: '8901',
        title: "Vassel 8",
        description: "A short mission 8",
        start: "30/01/24, 14:40",
        end: "30/01/24, 15:57",
        status: "scheduled"
    },
    {
        uuID: '9012',
        title: "Vassel 9",
        description: "A short mission 9",
        start: "30/01/24, 16:01",
        end: "30/01/24, 18:22",
        status: "scheduled"
    },
    {
        uuID: '2234',
        title: "Vassel 10",
        description: "A short mission 10",
        start: "30/01/24, 16:44",
        end: "30/01/24, 18:36",
        status: "scheduled"
    },
]
const formatSortValueToIcon = (ascValue) => {
    if (ascValue > 0) return SortAsc;
    if (ascValue < 0) return SortDesc;
    return SortIcon;
}

const formatAscValueToIcon = (ascValue) => {
    if (ascValue > 0) return ascAscIcon;
    if (ascValue < 0) return ascDescIcon;
    return ascIcon;
}
export const ManageMissions = () => {
    const dispatch = useDispatch()
    const [filters, setFilters] = useState(tableFilters);
    const [isLoading, setIsLoading] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [missionToDelete, setMissionToDelete] = useState(null)
    const allMissions = useSelector(getAllMissions)

    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        if (allMissions) {
            console.log(allMissions)
            setFilteredData(allMissions);
            setTimeout(() => {
                let tableWrpr = document.querySelector('.table-wrpr-box');
                if (tableWrpr) {
                    tableWrpr.scrollLeft = tableWrpr.scrollWidth;
                }
            }, 10);
        }
    }, [allMissions]);

    const [allVessels, setAllVessel] = useState([]);
    const getAllFleets = async () => {
        let res = await api.fleet.getAllVessel();
        setAllVessel(res.data);
    }
    useEffect(() => {
        getAllFleets()
        const req = async () => {
            setIsLoading(true)
            const res = await dispatch(fetchAllMission())
            setIsLoading(false)
        }

        req()

    }, []);


    const handleascChange = (index) => {
        setFilters((oldFilters) => {
            const newFilters = [...oldFilters];
            const target = newFilters[index].ascValue;
            if (target === 0) newFilters[index].ascValue = 1;
            else if (target === 1) newFilters[index].ascValue = -1;
            else if (target === -1) newFilters[index].ascValue = 0;
            return newFilters
        });
    }

    const handleSortChange = (index) => {
        const newFilters = [...filters];
        const target = newFilters[index].sortValue;
        if (target === 0) newFilters[index].sortValue = 1;
        else if (target === 1) newFilters[index].sortValue = -1;
        else if (target === -1) newFilters[index].sortValue = 0;
        setFilters(newFilters);
    }

    const handleFilterInput = (e, index) => {
        const { name, value } = e.target;
        setFilters((oldFilters) => {
            const newFilters = [...oldFilters];
            newFilters[index].filterValue = value;
            return newFilters;
        });

    }

    useEffect(() => {
        // TODO: Implement sorting and filtering
        if (!allMissions) return
        const newFilteredData = allMissions.filter((row) => {
            return filters.every((filter, index) => {
                if (filter.filterValue === '') return true;
                if (filter.id === 'date') {
                    console.log(filter.filterValue.start);
                    const after = filter.filterValue?.start ? new Date(row.start) > new Date(filter.filterValue.start) : true
                    const before = filter.filterValue?.end ? new Date(row.end) < new Date(filter.filterValue.end) : true
                    return after && before
                };
                console.log(filter.id);
                return String(row[filter.id]).toLowerCase().includes(filter.filterValue.toLowerCase());
            });
        });
        newFilteredData.sort((a, b) => {
            return filters.reduce((acc, filter, index) => {
                if (filter.sortValue === 0) return acc;
                const valueA = a[filter.id];
                const valueB = b[filter.id];
                if (valueA < valueB) return acc + filter.sortValue;
                if (valueA > valueB) return acc - filter.sortValue;
                return acc;
            }, 0);
        });

        newFilteredData.sort((a, b) => {
            return filters.reduce((acc, filter, index) => {
                if (!filter.asc) return acc;
                if (filter.ascValue === 0) return acc;
                const valueA = a[filter.id];
                const valueB = b[filter.id];
                if (valueA < valueB) return acc + filter.ascValue;
                if (valueA > valueB) return acc - filter.ascValue;
                return acc;
            }, 0);
        });

        setFilteredData(newFilteredData);
    }, [filters]);

    const deleteMission = async () => {
        if (!missionToDelete) return
        const req = await dispatch(fetchDeleteMission(missionToDelete))
        setConfirmModalOpen(false)
    }


    const [createMissionModal, setCreateMissionModal] = useState(false)

    return (
        <>
            <header
                className="flex gap-5 justify-between pb-2 w-full border-b border-solid border-b-[color:var(--black-25,rgba(11,11,11,0.25))] max-md:flex-wrap max-md:max-w-full">
                <div className="flex gap-2 justify-between max-md:flex-wrap max-md:max-w-full">
                    <div className="flex flex-col flex-1 justify-center text-neutral-950 pr-2">
                        <div
                            className="font-semibold tracking-wider whitespace-nowrap text-[16px] dark:text-[#FFFFFF]">Mission Planner
                        </div>
                        <div className="text-3xl font-bold tracking-wider whitespace-nowrap  dark:text-[#FFFFFF]">Manage Missions
                        </div>
                    </div>
                </div>
                <div className='flex items-center'>
                    <button onClick={() => setCreateMissionModal(true)}
                        className="group flex items-center justify-center p-[4px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border text-[#E42320] border-[#E42320] bg-transparent hover:bg-[#E4232040] active:bg-transparent active:text-[#0B0B0B80] active:border-[#0B0B0B80] w-full">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="min-w-[24px] icon-oshen-primary group-active:icon-black-50">
                            <path
                                d="M18.7693 10.0005L20.7092 8.00057C20.8955 7.81322 21 7.55978 21 7.2956C21 7.03143 20.8955 6.77799 20.7092 6.59063L17.3694 3.29078C17.182 3.10454 16.9286 3 16.6644 3C16.4002 3 16.1468 3.10454 15.9594 3.29078L13.9995 5.23069L18.7693 10.0005ZM3 16.2502V21H7.74979L17.7094 11.0404L12.9596 6.29065L3 16.2502Z"
                                fill="#0B0B0B"></path>
                        </svg>
                        Create Mission
                    </button>
                </div>
            </header>
            <div className="dashboard-layout__content pt-5 flex-1 flex flex-col">
                {!isLoading && allMissions && allMissions.length > 0 && <div
                    className="overflow-x-auto relative rounded border border-solid border-neutral-950 border-opacity-30 flex-1 bg-[#fff] dark:bg-[#0B0B0B] table-wrpr-box"
                >
                    <table className="min-w-full text-[12px]">
                        <thead className="bg-white dark:bg-[#0B0B0B]">
                            <tr>
                                {
                                    filters.map((filter, index) => {
                                        return <th key={filter.id} className="text-left py-1 px-2">
                                            <div className="flex gap-2 items-center sortable-column filter-column">
                                                <span
                                                    className="sortable-column__text dark:text-white whitespace-nowrap">{filter.title}</span>
                                                {
                                                    filter.fType === 'date' && <DatePicker
                                                        className="min-h-8 text-xs tracking-wide whitespace-nowrap bg-white rounded border border-solid border-[color:var(--black-25,rgba(11,11,11,0.25))] text-neutral-950 text-opacity-50 dark:bg-[#0B0B0B]"
                                                        toggleCalendarOnIconClick
                                                        // showTimeSelect
                                                        selectsRange={true}
                                                        onChange={(dates) => {
                                                            const [start, end] = dates;
                                                            setFilters((oldFilters) => {
                                                                const newFilters = [...oldFilters];
                                                                newFilters[index].filterValue.start = start;
                                                                newFilters[index].filterValue.end = end;
                                                                return newFilters;
                                                            });
                                                        }}
                                                        startDate={filter.filterValue.start}
                                                        endDate={filter.filterValue.end}
                                                    />
                                                    || filter.filterable && <input
                                                        // onInput={(e) => handleFilterInput(e, index)}
                                                        name={filter.name}
                                                        // value={filter.filterValue}
                                                        type="text"
                                                        className="p-1 bg-white dark:bg-[#0B0B0B] rounded border border-solid border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50" />
                                                }

                                                {
                                                    filter.asc &&
                                                    <span className="cursor-pointer sortable-column__icon asc-filter size-6"
                                                        onClick={() => handleascChange(index)}>
                                                        <img loading="lazy" src={formatAscValueToIcon(filter.ascValue)}
                                                            alt="Sort Icon" className="size-6" />
                                                    </span>
                                                }
                                                {
                                                    filter.sortable &&
                                                    <span
                                                        className="cursor-pointer sortable-column__icon sort-filter size-6"
                                                        onClick={() => handleSortChange(index)}>
                                                        <img loading="lazy" src={formatSortValueToIcon(filter.sortValue)}
                                                            alt="Sort Icon" className="size-6" />
                                                    </span>
                                                }
                                            </div>
                                        </th>
                                    })
                                }
                                <th className="text-left py-1 px-2 dark:text-white">Edit</th>
                                <th className="text-left py-1 px-2 dark:text-white">Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredData.map((row, index) => {
                                    return <tr
                                        key={row.id}
                                        className={`${index % 2 ? 'bg-[#fbfbfb]' : 'bg-gray-100s'} ${index === 0 ? 'border-t' : ''} border-b border-solid border-neutral-950 border-opacity-10 text-left`}
                                    >
                                        <td className="py-1 px-2">{row.id}</td>
                                        <td className="py-1 px-2">{allVessels.find(item => item.id === row.vesselId)?.name || row.vesselId}</td>
                                        <td className="py-1 px-2">{row.description}</td>
                                        <td className="py-1 px-2">{row.start ? new Date(row.start).toLocaleDateString() : ""}</td>
                                        <td className="py-1 px-2">{row.end ? new Date(row.end).toLocaleDateString() : ""}</td>
                                        <td className="py-1 px-2">
                                            {
                                                row.status && statusButtons[row.status]
                                            }
                                        </td>
                                        <td className="py-1 px-2 dark-bright">
                                            <NavLink to={`/mission-planner/map/${row.id}`} className='flex size-6'>
                                                <img src={EditIcon} className='size-6' />
                                            </NavLink>
                                        </td>
                                        <td onClick={() => {
                                            setMissionToDelete(row.id)
                                            setConfirmModalOpen(true)
                                        }
                                        } className="py-1 px-2 dark-bright">
                                            <button className='block size-6'><img src={DeleteIcon} className='size-6' />
                                            </button>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>

                    {false && <div
                        className="sticky bottom-0 left-0 bg-white dark:bg-[#0B0B0B] table-pagination-footer p-3 border-t border-solid bg-zinc-50 border-neutral-950 border-opacity-10">
                        <ul className="table-pagination flex justify-center items-center gap-2 text-[12px] font-semibold">
                            <li className="table-pagination__item">
                                <button className="block table-pagination__button">
                                    <img src={ArrowLeft} />
                                </button>
                            </li>
                            <li className="table-pagination__item active">
                                <button className="block table-pagination__button">1</button>
                            </li>
                            <li className="table-pagination__item">
                                <button className="block table-pagination__button">2</button>
                            </li>
                            <li className="table-pagination__item">
                                <button className="block table-pagination__button">3</button>
                            </li>
                            <li className="table-pagination__item">
                                <button className="block table-pagination__button">4</button>
                            </li>
                            <li className="table-pagination__item">
                                <button className="block table-pagination__button">5</button>
                            </li>
                            <li className="table-pagination__item">
                                <button className="block table-pagination__button">...</button>
                            </li>
                            <li className="table-pagination__item">
                                <button className="block table-pagination__button">
                                    <img src={ArrowRight} />
                                </button>
                            </li>
                        </ul>
                    </div>}
                </div>}


                {
                    isLoading && <div>Loading</div>
                }
                {
                    !isLoading && allMissions && allMissions.length === 0 && <div>No Missions Found</div>
                }

                {createMissionModal && <CreateNewMissionModal onClose={() => setCreateMissionModal(false)} />}
            </div>
            {
                confirmModalOpen &&
                <ConfirmationModal
                    title="Confirm Deletion"
                    message="Are you sure you want to delete this item? This action cannot be undone."
                    close={() => setConfirmModalOpen(false)}
                    onConfirm={deleteMission} />
            }
        </>
    )
}

export default ManageMissions

